import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IdxChart } from './idxChart';
import { IdxBar } from './idxBar';

import { handleSubscription } from 'utils/actionListener';
import { apiExec, showMessageErroWithoutStatusCode } from 'services/api';
import {
	setToggleAsset,
	setToggleCount,
} from 'store/modules/idxLiveCharts/actions';

export const IdxLiveCharts = ({ registerCallback, unRegisterCallback }) => {
	const dispatch = useDispatch();

	const [finValue, setFinValue] = useState(0.0);
	const winSymbol = useRef();
	const winFutSymbol = 'WINFUT';
	const wdoSymbol = useRef();
	const wdoFutSymbol = 'WDOFUT';

	const idxChart1Ref = useRef();
	const idxChart2Ref = useRef();

	const [lastSymbol, setLastSymbol] = useState(winFutSymbol);

	const isToggledWin = useSelector(
		state => state.idxLiveCharts.toggleAsset['WIN']
	);
	const isToggledWdo = useSelector(
		state => state.idxLiveCharts.toggleAsset['WDO']
	);

	const toggleCount = useSelector(state => state.idxLiveCharts.toggleCount);

	const [symbols, setSymbols] = useState([winFutSymbol, wdoFutSymbol]);
	const symbolsFull = useRef([]);
	const symbolsRef = useRef([]);
	const formattedStrategiesRef = useRef([]);

	useEffect(() => {
		getCurrentStrategies();
		let snapshotId = subscribeSnapshot();
		return () => {
			unsubscribeSnapshot(snapshotId);
		};
	}, [toggleCount]);

	useEffect(() => {
		symbolsRef.current = symbols;

		adjustAcumFinance();
	}, [symbols]);

	function adjustAcumFinance() {
		let acumValue = 0.0;

		Object.values(formattedStrategiesRef.current).map(strategy => {
			if (symbolsRef.current.includes(strategy.symbol)) {
				acumValue = acumValue + strategy.avgPrice;
			}
		});

		setFinValue(isNaN(acumValue) ? 0.0 : acumValue);
	}

	const queryParams = {
		params: {
			detailed: 1,
		},
	};

	function getCurrentStrategies() {
		apiExec.get('algos', queryParams).then(response => {
			const strategies = response.data.records;
			strategies.forEach(strategy => {
				adjustFromSnapshot(strategy);
			});

			adjustAcumFinance();
		});
	}

	function cancelAllStrategies(asset) {
		Object.values(formattedStrategiesRef.current).map(strategy => {
			if (strategy.symbol.startsWith(asset) || asset === 'ALL') {
				if (symbolsRef.current.includes(strategy.symbol)) {
					if ([2, 3, 4, 9].includes(parseInt(strategy.status))) {
						apiExec
							.post(
								`/algos/${strategy.clordid}/commands?Action=CANCEL`
							)
							.catch(error => {
								showMessageErroWithoutStatusCode(error);
							});
					}
				}
			}
		});
	}

	function adjustFromSnapshot(strategy) {
		if (!formattedStrategiesRef.current[strategy.ClOrdID]) {
			if (
				strategy.TargetStrategy === '1032' &&
				strategy.Legs?.length > 0 &&
				symbolsFull.current.includes(strategy.Legs[0].LegSymbol)
			) {
				formattedStrategiesRef.current[strategy.ClOrdID] = {
					clordid: strategy.ClOrdID,
					avgPrice: strategy.AvgPrice ?? 0.0,
					status: strategy.OrdStatus ?? strategy.Status,
					symbol: strategy.Legs[0].LegSymbol,
					netQuantity: 0,
					legs: [],
				};
			} else {
				return;
			}
		} else {
			if (strategy.AvgPrice !== undefined) {
				formattedStrategiesRef.current[strategy.ClOrdID].avgPrice =
					strategy.AvgPrice;
			}
			if (
				strategy.OrdStatus !== undefined ||
				strategy.Status !== undefined
			) {
				formattedStrategiesRef.current[strategy.ClOrdID].status =
					strategy.OrdStatus ?? strategy.Status;
			}
		}

		const symbol = formattedStrategiesRef.current[strategy.ClOrdID].symbol;

		const strategyData = {
			strategyId: strategy.ClOrdID,
			symbol: symbol,
			prices: [],
		};

		let sendOrdersToChart = false;

		if (strategy.Legs) {
			formattedStrategiesRef.current[strategy.ClOrdID].netQuantity = 0;
		}

		strategy.Legs?.forEach(leg => {
			if (
				!formattedStrategiesRef.current[strategy.ClOrdID].legs[
					leg.LegRefID
				]
			) {
				formattedStrategiesRef.current[strategy.ClOrdID].legs[
					leg.LegRefID
				] = {
					legRefID: leg.LegRefID,
					side: leg.LegSide,
					avgPrice: 0,
					execQty: 0,
					symbol: symbol,
				};
			}

			if (leg.LegAvgPx) {
				formattedStrategiesRef.current[strategy.ClOrdID].legs[
					leg.LegRefID
				].avgPrice = leg.LegAvgPx;
			}

			if (leg.LegExecQty) {
				formattedStrategiesRef.current[strategy.ClOrdID].legs[
					leg.LegRefID
				].execQty = leg.LegExecQty;
			}

			const side =
				formattedStrategiesRef.current[strategy.ClOrdID].legs[
					leg.LegRefID
				].side;

			const qtty =
				formattedStrategiesRef.current[strategy.ClOrdID].legs[
					leg.LegRefID
				].execQty;

			formattedStrategiesRef.current[strategy.ClOrdID].netQuantity +=
				side === '1' ? qtty : qtty * -1;

			if (leg.LegOpenOrdQuantity !== undefined) {
				sendOrdersToChart = true;
				if (leg.LegOpenOrdQuantity !== '0') {
					const quantities =
						leg.LegOpenOrdQuantity.split(';').map(Number);
					const prices = leg.LegOpenOrdPrice.split(';').map(Number);

					const baseQuantity = quantities[0];
					const basePrice = prices[0];

					const type =
						leg.LegRefID.slice(-1) === '1'
							? side === '1'
								? 'C'
								: 'V'
							: 'TP';

					const basePriceEntry = {
						quantity: baseQuantity,
						price: basePrice,
						side: side,
						type: type,
					};

					strategyData.prices.push(basePriceEntry);

					formattedStrategiesRef.current[strategy.ClOrdID].legs[
						leg.LegRefID
					];

					prices.slice(1).forEach((increment, index) => {
						const newPrice = {
							quantity: baseQuantity + quantities[index + 1],
							price: basePrice + increment,
							side: side,
							type: type,
						};

						strategyData.prices.push(newPrice);
					});
				}
			}
		});

		if (sendOrdersToChart) {
			if (idxChart1Ref.current) {
				idxChart1Ref.current.updatePriceLines(strategyData);
			}
			if (idxChart2Ref.current) {
				idxChart2Ref.current.updatePriceLines(strategyData);
			}
		}
	}

	function subscribeSnapshot() {
		const props = {
			action: 'subscribe',
			type: 'snapshot',
			key: 'subscribe',
			callbackFunction: data => {
				if (data.MsgType === 'USN') {
					adjustFromSnapshot(data);
					adjustAcumFinance();
				}
			},
		};

		return handleSubscription(props, registerCallback, unRegisterCallback);
	}

	function unsubscribeSnapshot(id) {
		const props = {
			action: 'unsubscribe',
			id: id,
		};

		handleSubscription(props, registerCallback, unRegisterCallback);
	}

	function handleClickToggle(untoggle, asset) {
		if (untoggle && toggleCount === 1) return;

		if (untoggle) {
			dispatch(setToggleCount(1));

			if (asset === 'WIN') {
				setSymbols([wdoSymbol.current]);
			} else {
				setSymbols([winSymbol.current]);
			}
		} else {
			dispatch(setToggleCount(2));

			setSymbols([winSymbol.current, wdoSymbol.current]);
		}

		dispatch(setToggleAsset(asset, !untoggle));
	}

	function setContinuousSymbol(symbol, asset) {
		if (asset === 'WIN') {
			if (winSymbol.current === symbol) return;
			winSymbol.current = symbol;
			setLastSymbol(symbol);
		} else if (asset === 'WDO') {
			if (wdoSymbol.current === symbol) return;
			wdoSymbol.current = symbol;
		}

		symbolsFull.current = [winSymbol.current, wdoSymbol.current];
		setSymbols([winSymbol.current, wdoSymbol.current]);

		getCurrentStrategies();
	}

	function setLastInteractSymbol(symbol) {
		setLastSymbol(symbol);
	}

	return (
		<>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					height: '100%',
					width: '100%',
				}}
			>
				<IdxBar
					id="idxBar"
					symbolDef={lastSymbol}
					finValue={finValue}
					isToggledWin={isToggledWin}
					isToggledWdo={isToggledWdo}
					handleClickToggle={handleClickToggle}
					cancelAllStrategies={cancelAllStrategies}
				/>

				<div
					style={{
						display: 'flex',
						flex: 1,
						gap: 5,
						backgroundColor: 'gray',
						paddingRight: 16,
					}}
				>
					{isToggledWin && (
						<IdxChart
							id="chart1"
							ref={idxChart1Ref}
							symbol={winFutSymbol}
							toggleCount={toggleCount}
							fractionDigits={0}
							bandUpperPerc={0.0082}
							bandLowerPerc={0.0082}
							setContinuousSymbol={setContinuousSymbol}
							setLastInteractSymbol={setLastInteractSymbol}
							registerCallback={registerCallback}
							unRegisterCallback={unRegisterCallback}
						/>
					)}
					{isToggledWdo && (
						<IdxChart
							id="chart2"
							ref={idxChart2Ref}
							symbol={wdoFutSymbol}
							toggleCount={toggleCount}
							fractionDigits={2}
							bandUpperPerc={0.007}
							bandLowerPerc={0.007}
							setContinuousSymbol={setContinuousSymbol}
							setLastInteractSymbol={setLastInteractSymbol}
							registerCallback={registerCallback}
							unRegisterCallback={unRegisterCallback}
						/>
					)}
				</div>
			</div>
		</>
	);
};
