import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
	ClickAwayListener,
	Fade,
	List,
	MenuItem,
	Paper,
	Popper,
} from '@mui/material';

import flexScanImage from 'assets/images/flexscan_white_logo.png';
import tradeCertoImage from 'assets/images/trade-certo-branco.png';

import { changeActiveProduct } from 'utils/changeActiveProduct';

const PRODUCT_IMAGES = {
	FLEX_PRO: flexScanImage,
	FLEX_CUSTOM: flexScanImage,
	FLEX_ANALYTIC: flexScanImage,
	FLEX_STANDARD: flexScanImage,
	FLEX_ADVANCED: flexScanImage,
	TRADE_CERTO: tradeCertoImage,
};

export const ProductSelector = () => {
	const history = useHistory();
	const { activeProduct, userSignatures } = useSelector(
		state => state.products
	);

	const [anchorEl, setAnchorEl] = useState(null);
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	const filteredSignatures = userSignatures.filter(
		signature => signature.product.code !== activeProduct.code
	);

	function handleOpenMenu(event) {
		if (filteredSignatures.length > 0) {
			setAnchorEl(anchorEl ? null : event.currentTarget);
			setIsMenuOpen(true);
		}
	}

	function handleCloseMenu() {
		setAnchorEl(null);
		setIsMenuOpen(false);
	}

	return (
		<>
			<img
				src={PRODUCT_IMAGES[activeProduct.code]}
				alt="flexscan"
				onClick={handleOpenMenu}
				style={{
					cursor:
						filteredSignatures.length === 0 ? 'default' : 'pointer',
				}}
			/>

			<Popper
				open={isMenuOpen}
				anchorEl={anchorEl}
				placement="bottom"
				transition
			>
				{({ TransitionProps }) => (
					<ClickAwayListener onClickAway={handleCloseMenu}>
						<Fade {...TransitionProps}>
							<Paper
								elevation={12}
								square={false}
								sx={{ mt: 1.5, ml: 2 }}
							>
								<List sx={{ py: 0, borderRadius: 1 }}>
									{filteredSignatures.map(signature => (
										<MenuItem
											key={signature.product.code}
											onClick={() =>
												changeActiveProduct(
													signature,
													history
												)
											}
										>
											<img
												src={
													PRODUCT_IMAGES[
														signature.product.code
													]
												}
												style={{ width: 100 }}
											/>
										</MenuItem>
									))}
								</List>
							</Paper>
						</Fade>
					</ClickAwayListener>
				)}
			</Popper>
		</>
	);
};
