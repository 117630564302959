import React from 'react';

import { Grid } from '@mui/material';

import { GapExitParams } from './GapExitParams';
import { GapMarketPrice } from './GapMarketPrice';
import { GapEntryParams } from './GapEntryParams';

export const GapBullet = () => {
	return (
		<Grid id="gap-bullet-container" item container xs={12} spacing={1}>
			<Grid item container xs={1.5}>
				<GapMarketPrice />
			</Grid>

			<Grid item container xs={8}>
				<GapEntryParams />
			</Grid>

			<Grid item container xs={2.5}>
				<GapExitParams />
			</Grid>
		</Grid>
	);
};
