import React, { useEffect } from 'react';
import { useLocation, useHistory, NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { differenceInMinutes } from 'date-fns/esm';

import {
	CssBaseline,
	Drawer,
	styled,
	List,
	ListItemButton,
	ListItem,
	ListItemIcon,
	Typography,
	Box,
	Toolbar,
	Badge,
} from '@mui/material';
import { QuestionMark } from '@mui/icons-material';

import { setLastUpdated } from 'store/modules/auth/actions';
import { doLogout } from 'store/modules/auth/actions';
import { getApplicationModulesList } from 'utils/applicationModulesList';

import { LightTooltip } from 'components/LightTooltip';

const StyledDrawer = styled(Drawer, {
	shouldForwardProp: prop => prop !== 'open',
})(() => ({
	width: 50,
	flexShrink: 0,
	whiteSpace: 'nowrap',
	boxSizing: 'border-box',
	'& .MuiDrawer-paper': {
		background: '#333',
		overflowX: 'hidden',
		width: 50,
		border: '0 !important',
	},
}));

export const Navbar = () => {
	const history = useHistory();
	const location = useLocation();
	const dispatch = useDispatch();

	const { username, email, name, lastUpdated, inactivityLogoutInterval } =
		useSelector(state => state.auth);
	const { market, execution } = useSelector(state => state.globalStatus);

	useEffect(() => {
		const diferenceInMinutes = differenceInMinutes(
			new Date(),
			new Date(lastUpdated)
		);
		if (diferenceInMinutes >= inactivityLogoutInterval) {
			dispatch(doLogout(history));
		} else {
			dispatch(setLastUpdated(new Date()));
		}
	}, [location.pathname]);

	const modulesList = getApplicationModulesList();

	return (
		<>
			<Toolbar>
				<CssBaseline />

				<StyledDrawer variant="permanent">
					<Box
						sx={{
							flex: 1,
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'space-between',
							pt: 2,
						}}
					>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
							}}
						>
							<List>
								{modulesList.map(module => (
									<LightTooltip
										key={module.path}
										title={module.label}
										arrow
										placement="right"
									>
										<ListItem
											sx={{
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center',
												'&:hover': {
													a: {
														svg: { fill: '#fff' },
													},
												},
												'a.active': {
													svg: { fill: '#f7c600' },
												},
												a: {
													color: '#fff',
													textDecoration: 'none',
													svg: { fill: '#ccc' },
												},
											}}
										>
											<NavLink to={module.path}>
												<ListItemButton
													sx={{ height: 40 }}
												>
													<ListItemIcon
														sx={{ minWidth: 0 }}
													>
														{module.path ===
														'/idxlivecharts' ? (
																<Badge
																	badgeContent={
																		'NEW'
																	}
																	color="secondary"
																	overlap="rectangular"
																>
																	<module.icon fontSize="large" />
																</Badge>
															) : (
																<module.icon fontSize="large" />
															)}
													</ListItemIcon>
												</ListItemButton>
											</NavLink>
										</ListItem>
									</LightTooltip>
								))}
							</List>
						</Box>

						<Box>
							<LightTooltip title="Ajuda" arrow placement="right">
								<ListItem key="Ajuda" sx={{ p: 0 }}>
									<ListItemButton
										onClick={() =>
											window.executeHome(
												name,
												email,
												username
											)
										}
										sx={{ height: 40 }}
									>
										<ListItemIcon
											sx={{
												color: '#ccc',
												minWidth: 0,
												ml: -0.5,
											}}
										>
											<QuestionMark fontSize="large" />
										</ListItemIcon>
									</ListItemButton>
								</ListItem>
							</LightTooltip>

							<Box
								sx={{
									py: 1.5,
									gap: 0.5,
									display: 'flex',
									alignItems: 'center',
									flexDirection: 'column',
								}}
							>
								<Typography
									variant="body1"
									sx={{ m: 'auto auto 1rem auto' }}
								>
									{process.env.REACT_APP_VERSION}
								</Typography>

								<Box sx={{ display: 'flex', gap: 1 }}>
									<LightTooltip arrow title="Mercado">
										<Box
											sx={{
												height: 12,
												width: 12,
												cursor: 'pointer',
												borderRadius: '100%',
												bgcolor: market
													? '#4caf50'
													: '#f44336',
											}}
										/>
									</LightTooltip>

									<LightTooltip arrow title="Execução">
										<Box
											sx={{
												height: 12,
												width: 12,
												cursor: 'pointer',
												borderRadius: '100%',
												bgcolor: execution
													? '#4caf50'
													: '#f44336',
											}}
										/>
									</LightTooltip>
								</Box>
							</Box>
						</Box>
					</Box>
				</StyledDrawer>
			</Toolbar>
		</>
	);
};
