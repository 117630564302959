import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga4';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { calcIvol } from '@investflex/iflexquantjs';
import { useSelector, useDispatch } from 'react-redux';

import { Box, colors } from '@mui/material';

import { getMarketPrice } from 'services/getMarketPrice';
import { getVolLastQuotes } from 'services/getVolLastQuotes';
import { apiEls, apiMarketData } from 'services/api';

import {
	strategyUpdate,
	additionalUpdate,
	replaceCustomParameters,
	replaceStrategyLegs,
	resetBottomBullet,
	updateCustomParameters,
	updateStrategyLeg,
	getCalcTaxTriggerFinanciamento,
	updateMarketPrices,
	updateStrategyInfo,
	updateContext,
	setMarketPriceLoading,
	orderEntry,
	updateStrategyInfosFromApi,
	setIsRecurrenceBullet,
} from '../../../store/modules/bottomBullet/actions';
import { store } from 'store';
import {
	handleStrategyCode,
	handleOrdType,
	getStrategyCodeUsingTemplatesIds,
	getCategoryFromStrategyCode,
	bulletInitialState,
	getSubUid,
} from 'utils/strategies.js';
import { getStrategyTemplate } from 'utils/templates';
import { NEW, validateTypeBullet } from '../../../utils/bulletContext';

import BodyBullet from '../BodyBullet';
import MaterialFooter from './MaterialFooter';
import { Header } from 'components/Bullet/Header';
import { MaterialSnackbar } from '../MaterialSnackbar';
import { MaterialBulletContainer } from './styles';
import { SavedTable } from 'components/UserBullets/SavedTable';
import { RecentTable } from 'components/UserBullets/RecentTable';

const MaterialTabs = ({
	setPaperInfo,
	onSuggestionsFetchRequested,
	suggestions,
}) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const oldExecType = useSelector(state => state.auth.execType);
	const newExecType = useSelector(
		state => state.configs.globalSettings?.userSettings?.bullet.execType
	);
	const { bullet } = useSelector(state => state.bottomBullet);

	const { userType } = useSelector(state => state.auth);

	const defaultAccount =
		store.getState().configs.globalSettings.userSettings.bullet
			.defaultAccount ?? 'Selecione';

	const [lastVol, setLastVol] = useState({});
	const [changeBullet, setChangeBullet] = useState(false);
	const [snackBar, setSnackBar] = useState({
		severity: 'error',
		isOpen: false,
		message: '',
	});
	const { isSavedListVisible, isRecentListVisible } = bullet;
	const [marketPrice, setMarketPrice] = useState({
		bid: null,
		mid: null,
		ask: null,
	});

	const isListsOpen = isSavedListVisible || isRecentListVisible;

	let controller = null;

	let optInfos = {},
		secondOptInfos = {},
		firstResult = {},
		secondResult = {};

	const setIsSavedListVisible = value => {
		dispatch(additionalUpdate('isSavedListVisible', value));
	};

	const setIsRecentListVisible = value => {
		dispatch(additionalUpdate('isRecentListVisible', value));
	};

	useEffect(() => {
		const stratCode = (bullet?.content?.StrategyCode ?? '').toUpperCase();
		ReactGA.event({
			category: 'Execution',
			action: `OPEN_BULLET_${stratCode}`,
		});

		if (controller) {
			controller.abort();
		}

		dispatch(additionalUpdate('resetMarketPrices', false));
		setMarketPrice({});
	}, [
		bullet.isOpen,
		changeBullet,
		bullet.resetMarketPrices,
		bullet.content.StrategyCode,
		dispatch,
	]);

	function openBullet() {
		dispatch(additionalUpdate('isOpen', true));
		setIsSavedListVisible(false);
		setIsRecentListVisible(false);
	}

	function openLists(index) {
		if (index === 1) {
			setIsRecentListVisible(true);
			setIsSavedListVisible(false);
		} else {
			setIsRecentListVisible(false);
			setIsSavedListVisible(true);
		}
	}

	const closeBullet = hidden => {
		if (!hidden) {
			setLastVol({});
			resetBottomBullet(bulletInitialState);
		}

		dispatch(additionalUpdate('isOpen', false));
		setIsSavedListVisible(false);
		setIsRecentListVisible(false);
	};

	function resetCancelAxiosToken() {
		controller = null;
	}

	const handleBullet = (key, value, isSelect, subType) => {
		dispatch(setIsRecurrenceBullet(false));
		dispatch(additionalUpdate(key, value));

		const hash = { ...bullet.content };

		hash.StrategyCode = handleStrategyCode(key, value, subType);

		const strategyCodeToGetTemplate = getStrategyCodeUsingTemplatesIds(
			hash.StrategyCode
		);
		//pegar template
		let defaultTemplate = getStrategyTemplate(strategyCodeToGetTemplate);

		defaultTemplate.StrategyCode = hash.StrategyCode;
		defaultTemplate.ExpireDate = format(new Date(), 'yyyyMMdd');
		dispatch(strategyUpdate(defaultTemplate));

		const spreadNumLegs = +hash.StrategyCode.match(/\d+/);

		const numLegs =
			strategyCodeToGetTemplate === 'spread'
				? spreadNumLegs === 0
					? 2
					: spreadNumLegs
				: defaultTemplate.StrategyLegs.length;

		// So existe um template para varias spreads, entao apenas acrescentamos mais legs
		if (strategyCodeToGetTemplate === 'spread' && numLegs > 2) {
			const tempIndex = defaultTemplate.StrategyLegs.length;
			const lastLeg = defaultTemplate.StrategyLegs[tempIndex - 1];
			for (let i = tempIndex; i < numLegs; i++) {
				defaultTemplate.StrategyLegs.push({ ...lastLeg });
				defaultTemplate.StrategyLegs[i].LegSide =
					i % 2 === 0 ? '1' : '2';
			}
		}

		if (
			hash.StrategyCode.includes('spread') ||
			hash.StrategyCode === 'lgshort'
		) {
			//zerar os marketsSpread
			setMarketPrice({ reset: true });
		} else if (hash.StrategyCode === 'financ') {
			dispatch(updateMarketPrices(0.0));
		}

		if (hash.StrategyCode === 'sorder') {
			//tipo saida tem stop por padrao
			defaultTemplate.CustomParameters.stop =
				key === 'subUid' && value === 4;
			defaultTemplate.CustomParameters.CustomOrdType =
				handleOrdType(value);
		} else if (
			hash.StrategyCode === 'fxorder' &&
			value === 4 &&
			hash.StrategyLegs[0].LegQuantity
		) {
			defaultTemplate.CustomParameters.OrderQuantity =
				hash.StrategyLegs[0].LegQuantity;
		}

		if (
			hash.StrategyCode === 'fxorder' &&
			key === 'subUid' &&
			value === 3
		) {
			defaultTemplate.CustomParameters.stop = false;
			defaultTemplate.CustomParameters.StopType = 2;
		}

		//faz dispatch com o valor do contexto 'NEW' ao abrir a boleta
		dispatch(updateContext(NEW));

		//faz replace dos customsParameters para os valores presentes no template
		dispatch(replaceCustomParameters(defaultTemplate.CustomParameters));

		//faz replace dos StrategyLegs para os valores presentes na funcao getDefaultLegs
		dispatch(replaceStrategyLegs(defaultTemplate.StrategyLegs));

		const tempCategory =
			getCategoryFromStrategyCode(bullet.content.StrategyCode) ??
			bullet.category;
		//matem os dados da ultima boleta
		if ([1, 5].includes(subType) && `${subType}` === `${tempCategory}`) {
			const replaceLeg = {
				LegSymbol: hash.StrategyLegs[0].LegSymbol,
				LegSide: hash.StrategyLegs[0].LegSide,
				LegSecurityExchange: hash.StrategyLegs[0].LegSecurityExchange,
				LegQuantity: hash.StrategyLegs[0].LegQuantity,
				LegMaxClipSize: hash.StrategyLegs[0].LegMaxClipSize,
			};
			if (hash.CustomParameters.stop === true) {
				if (value === 4) {
					dispatch(
						updateCustomParameters({
							OrderQuantity: hash.CustomParameters.OrderQuantity,
						})
					);
				} else {
					replaceLeg.LegQuantity =
						hash.CustomParameters.OrderQuantity;
				}
			}
			if (replaceLeg.LegMaxClipSize) {
				replaceLeg.LegMaxClipSize = hash.StrategyLegs[0].LegMaxClipSize;
			}
			if (value === 9) {
				replaceLeg.LegMaxClipSize = bullet.StrategyInfos[0].roundLot;
				replaceLeg.LegMinClipSize = bullet.StrategyInfos[0].roundLot;
			} else if (value === 7) {
				replaceLeg.LegMaxClipSize = bullet.StrategyInfos[0].roundLot;
			}
			dispatch(updateStrategyLeg(replaceLeg, 0));
		} else if (
			[2, 3].includes(subType) &&
			[2, 3].includes(tempCategory) &&
			subType !== tempCategory
		) {
			const replaceFirstLeg = {
				LegSymbol: hash.StrategyLegs[0].LegSymbol,
				LegSide: hash.StrategyLegs[0].LegSide,
				LegSecurityExchange: hash.StrategyLegs[0].LegSecurityExchange,
				LegQuantity: hash.StrategyLegs[0].LegQuantity,
				LegMaxClipSize: hash.StrategyLegs[0].LegMaxClipSize,
				LegResting: hash.StrategyLegs[0].LegResting,
			};
			const replaceSecondLeg = {
				LegSymbol: hash.StrategyLegs[1].LegSymbol,
				LegSide: hash.StrategyLegs[1].LegSide,
				LegSecurityExchange: hash.StrategyLegs[1].LegSecurityExchange,
				LegQuantity: hash.StrategyLegs[1].LegQuantity,
				LegMaxClipSize: hash.StrategyLegs[1].LegMaxClipSize,
				LegResting: hash.StrategyLegs[1].LegResting,
			};
			if (replaceFirstLeg.LegSide === replaceSecondLeg.LegSide) {
				replaceFirstLeg.LegSide = '1';
				replaceSecondLeg.LegSide = '2';
			}
			dispatch(updateStrategyLeg(replaceFirstLeg, 0));
			dispatch(updateStrategyLeg(replaceSecondLeg, 1));
		} else {
			dispatch(
				additionalUpdate(
					'StrategyInfos',
					defaultTemplate.StrategyLegs.map(() => {
						return {};
					})
				)
			);
		}

		dispatch(additionalUpdate('legs', defaultTemplate.StrategyLegs.length));

		dispatch(additionalUpdate('clone', false));
		dispatch(additionalUpdate('revert', false));

		//tratamento para os casos de chamada com key uid (mudanca de categoria)
		if (subType === 'vol') {
			dispatch(strategyUpdate(defaultTemplate));
		}

		if (isSelect === true) {
			openBullet();
		}

		setChangeBullet(!changeBullet);

		if (store.getState().auth.accounts?.length > 2) {
			dispatch(additionalUpdate('account', defaultAccount));
		} else if (userType.id === 5 || userType.id === 2) {
			dispatch(additionalUpdate('account', ''));
		}
	};

	const setSpreaAuxPrice = (strategyLegs, trigger) => {
		if (
			strategyLegs.some(
				leg =>
					leg.LegQuantity === undefined ||
					leg.LegSymbol === undefined ||
					leg.LegSymbol === '' ||
					leg.LegQuantity === 0
			)
		) {
			setMarketPrice({});
			return;
		}

		if (trigger === 16) {
			dispatch(
				getCalcTaxTriggerFinanciamento(
					strategyLegs[1].LegSymbol,
					strategyLegs[0].LegSymbol
				)
			);
		} else {
			controller = new AbortController();

			getMarketPrice(
				trigger,
				strategyLegs,
				setMarketPrice,
				controller,
				resetCancelAxiosToken
			);
		}
	};

	const handleChange = (type, subType, value, index, error) => {
		const hash = { ...bullet.content };

		if (
			(subType === 'LegQuantity' || subType === 'OrderQuantity') &&
			value === 0
		) {
			return;
		}

		let obj = {};
		obj[subType] = value;

		if (type === 'Leg') {
			dispatch(updateStrategyLeg(obj, index));
		} else if (type === 'Custom') {
			dispatch(updateCustomParameters(obj));
		}

		if (
			hash.StrategyCode.includes('spread') ||
			hash.StrategyCode === 'lgshort'
		) {
			if (
				['LegQuantity', 'LegSide', 'LegSymbol', 'Trigger'].includes(
					subType
				)
			) {
				let tempTrigger = hash.CustomParameters.Trigger;
				let tempStrategyLegs = hash.StrategyLegs.map(leg => {
					return { ...leg };
				});
				if (subType === 'Trigger') {
					tempTrigger = value;
				} else {
					tempStrategyLegs[index][subType] = value;
				}

				setMarketPrice({});
				dispatch(setMarketPriceLoading(true));
				setSpreaAuxPrice(tempStrategyLegs, tempTrigger);
			}
		}

		if (error) {
			dispatch(additionalUpdate(error, false));
		}
	};

	function stepFunction(
		stepBtnType,
		stepType,
		stepSubType,
		stepSize,
		valueToStep,
		legIndex,
		max,
		min
	) {
		const hash = { ...bullet.content };

		if (!legIndex) {
			legIndex = 0;
		}

		if (
			(hash.CustomParameters.TriggerEntryType === 2 ||
				hash.CustomParameters.TriggerEntryType === undefined) &&
			parseFloat(valueToStep) >= 100.0 &&
			stepSubType === 'StopLoss' &&
			stepBtnType !== 'Down'
		) {
			handleChange(
				stepType,
				stepSubType,
				Math.round(parseFloat(valueToStep)),
				legIndex
			);
		} else if (
			(stepBtnType === 'Up' && valueToStep >= 0) ||
			(stepBtnType === 'Down' && valueToStep < 0)
		) {
			const newValue = parseFloat(valueToStep) + parseFloat(stepSize);

			if (newValue <= max) {
				handleChange(stepType, stepSubType, newValue, legIndex);
			}
		} else if (
			(stepBtnType === 'Down' && valueToStep >= 0) ||
			(stepBtnType === 'Up' && valueToStep < 0)
		) {
			const newValue = parseFloat(valueToStep) - parseFloat(stepSize);

			if (newValue >= min) {
				handleChange(stepType, stepSubType, newValue, legIndex);
			}
		} else if (stepBtnType === 'Round') {
			if (
				['TriggerValue', 'ToMarketTriggerValue'].includes(stepSubType)
			) {
				return;
			}

			const newValue = Math.max(
				Math.min(parseFloat(valueToStep), max),
				min
			);

			handleChange(
				stepType,
				stepSubType,
				Math.round(parseFloat(newValue) / stepSize) * stepSize,
				legIndex
			);
		}
	}

	const formatDate = (value, type) => {
		const splitedValue = type === 1 ? value.split('-') : value.split('/');
		return type === 1
			? `${splitedValue[2]}/${splitedValue[1]}/${splitedValue[0]}`
			: `${splitedValue[1]}/${splitedValue[0]}/${splitedValue[2]}`;
	};

	// TODO -> Mover para um arquivo que englobe todas as boletas de volatilidade
	function getVolOptInfos(bullet, optIndex) {
		apiEls
			.get(`/search/${bullet.content.StrategyLegs[optIndex].LegSymbol}`)
			.then(response => {
				if (response.data.found) {
					if (optIndex === 0) {
						optInfos = {
							strikePrice: response.data._source?.strikePrice,
							workDaysUntilMaturity:
								response.data._source?.workDaysUntilMaturity,
							optionType: response.data._source?.optionType,
							_id: response.data._id,
						};
					} else {
						secondOptInfos = {
							strikePrice: response.data._source?.strikePrice,
							workDaysUntilMaturity:
								response.data._source?.workDaysUntilMaturity,
							optionType: response.data._source?.optionType,
							_id: response.data._id,
						};
					}
				}
			})
			.catch(error => console.log(error));
	}

	function getVolatility(volatilities) {
		Object.keys(volatilities).forEach(key => {
			if (
				volatilities[key] &&
				volatilities[key] > 0 &&
				volatilities[key] < 9999
			) {
				volatilities[key] = new Intl.NumberFormat('de-DE', {
					minimumFractionDigits: 2,
					maximumFractionDigits: 4,
				}).format(volatilities[key]);
			} else {
				volatilities[key] = '--';
			}
		});

		return volatilities;
	}

	const getVolResult = async (
		strike,
		tax,
		daysUntilMaturity,
		optionType,
		bullet,
		optIndex,
		underlyingIndex
	) => {
		let optValue, underValue;
		let askVol, midVol, bidVol;

		let optionValues = await getVolLastQuotes(bullet, optIndex, true);
		let underlyingValues = await getVolLastQuotes(
			bullet,
			underlyingIndex,
			false
		);

		if (underlyingValues?.askPx > underlyingValues?.bidPx) {
			if (underlyingValues?.askPx <= 0 || underlyingValues?.bidPx <= 0) {
				if (underlyingValues?.askPx > 0) {
					underValue = underlyingValues?.askPx;
				} else if (underlyingValues?.bidPx > 0) {
					underValue = underlyingValues?.bidPx;
				} else if (underlyingValues?.lastPx > 0) {
					underValue = underlyingValues?.lastPx;
				} else {
					underValue = underlyingValues?.prevClosePx;
				}
			} else {
				underValue =
					(underlyingValues?.askPx + underlyingValues?.bidPx) / 2;
			}
		} else {
			underValue =
				underlyingValues?.lastPx > 0
					? underlyingValues?.lastPx
					: underlyingValues?.prevClosePx;
		}

		if (underlyingIndex === 2) {
			if (
				bullet.content.StrategyLegs[0].LegSide == 1 &&
				bullet.content.StrategyLegs[1].LegSide == 2
			) {
				optValue =
					optIndex === 0
						? optionValues.optAskPx
						: optionValues.optBidPx;
			} else {
				optValue =
					optIndex === 1
						? optionValues.optAskPx
						: optionValues.optBidPx;
			}
		} else {
			if (bullet.content.StrategyCode === 'cvvol') {
				optValue = optionValues.optAskPx;
			} else {
				if (bullet.content.StrategyLegs[0].LegSide == 1) {
					optValue = optionValues.optAskPx;
				} else {
					optValue = optionValues.optBidPx;
				}
			}
		}

		if (optValue) {
			askVol =
				parseFloat(
					calcIvol(
						underValue,
						strike,
						tax / 100,
						bullet.content.CustomParameters.DaysToExpiration ||
							bullet.content.CustomParameters[
								'DaysToExpiration' + (optIndex + 1)
							] ||
							daysUntilMaturity,
						optValue,
						optionType
					)
				) * 100;
		} else {
			askVol = '--';
		}

		if (underlyingIndex === 2) {
			if (
				bullet.content.StrategyLegs[0].LegSide == 1 &&
				bullet.content.StrategyLegs[1].LegSide == 2
			) {
				optValue =
					optIndex === 1
						? optionValues.optAskPx
						: optionValues.optBidPx;
			} else {
				optValue =
					optIndex === 0
						? optionValues.optAskPx
						: optionValues.optBidPx;
			}
		} else {
			if (bullet.content.StrategyCode === 'cvvol') {
				optValue = optionValues.optBidPx;
			} else {
				if (bullet.content.StrategyLegs[0].LegSide == 2) {
					optValue = optionValues.optAskPx;
				} else {
					optValue = optionValues.optBidPx;
				}
			}
		}

		if (optValue) {
			bidVol =
				parseFloat(
					calcIvol(
						underValue,
						strike,
						tax / 100,
						bullet.content.CustomParameters.DaysToExpiration ||
							bullet.content.CustomParameters[
								'DaysToExpiration' + (optIndex + 1)
							] ||
							daysUntilMaturity,
						optValue,
						optionType
					)
				) * 100;
		} else {
			bidVol = '--';
		}

		if (askVol && askVol !== '--' && bidVol && bidVol !== '--') {
			midVol =
				parseFloat(
					calcIvol(
						underValue,
						strike,
						tax / 100,
						bullet.content.CustomParameters.DaysToExpiration ||
							bullet.content.CustomParameters[
								'DaysToExpiration' + (optIndex + 1)
							] ||
							daysUntilMaturity,
						(optionValues.optAskPx + optionValues.optBidPx) / 2,
						optionType
					)
				) * 100;
		} else {
			midVol = '--';
		}

		const vol = {
			askVol,
			bidVol,
			midVol,
		};

		if (underlyingIndex === 2) {
			if (optIndex === 0) {
				console.log(
					'vól de cima:',
					JSON.stringify({
						underValue,
						strike,
						tax: tax / 100,
						dte:
							bullet.content.CustomParameters.DaysToExpiration ||
							bullet.content.CustomParameters[
								'DaysToExpiration' + (optIndex + 1)
							] ||
							daysUntilMaturity,
						optValue,
						optionType,
					})
				);
				console.log(vol);

				firstResult = vol;
			} else {
				console.log(
					'vól de baixo:',
					JSON.stringify({
						underValue,
						strike,
						tax: tax / 100,
						dte:
							bullet.content.CustomParameters.DaysToExpiration ||
							bullet.content.CustomParameters[
								'DaysToExpiration' + (optIndex + 1)
							] ||
							daysUntilMaturity,
						optValue,
						optionType,
					})
				);
				console.log(vol);

				secondResult = vol;
			}
		} else {
			setLastVol(getVolatility(vol));
			dispatch(setMarketPriceLoading(false));
		}
	};

	function calcSkewVolatility(firstValue, secondValue) {
		if (firstValue === '--' || secondValue === '--') {
			return '--';
		}

		return new Intl.NumberFormat('de-DE', {
			minimumFractionDigits: 2,
			maximumFractionDigits: 4,
		}).format(secondValue - firstValue);
	}

	function getSkewVolatility(firstResult, secondResult) {
		let volatilities = {
			askVol: null,
			bidVol: null,
			midVol: null,
		};

		Object.keys(volatilities).forEach(key => {
			if (
				firstResult[key] === 99000000000000 ||
				firstResult[key] === -99000000000000 ||
				isNaN(firstResult[key])
			) {
				firstResult[key] = '--';
			}

			if (
				secondResult[key] === 99000000000000 ||
				secondResult[key] === -99000000000000 ||
				isNaN(secondResult[key])
			) {
				secondResult[key] = '--';
			}

			volatilities = {
				...volatilities,
				[key]: calcSkewVolatility(firstResult[key], secondResult[key]),
			};
		});

		console.log('vol calculada:', volatilities);
		return volatilities;
	}

	function validateLines(lines) {
		const separators = /,|;/;

		const linesForCreate = [];

		if (lines.length > 4) {
			setSnackBar({
				...snackBar,
				isOpen: true,
				message: 'Não é possivel criar uma estrategia nesse formato!',
			});
			return;
		}

		for (let line of lines) {
			if (!separators.test(line)) {
				setSnackBar({
					...snackBar,
					isOpen: true,
					message: 'Formato inválido para criar uma estratégia!',
				});
				return;
			}

			const infosPaste = line.split(/[;,]\s*/);

			if (infosPaste.length < 3) {
				setSnackBar({
					...snackBar,
					isOpen: true,
					message:
						'Não existe uma estratégia no formato válido para criar!',
				});
				return;
			}

			if (infosPaste.some(value => value === '')) {
				setSnackBar({
					...snackBar,
					isOpen: true,
					message:
						'Nenhum valor pode ser vazio para criação da estratégia!',
				});
				return;
			}

			const [symbol, side, quantity, price] = infosPaste;

			if (!symbol) {
				setSnackBar({
					...snackBar,
					isOpen: true,
					message: 'Nome do ativo é inválido!',
				});
				return;
			}

			if (!['C', 'V'].includes(side)) {
				setSnackBar({
					...snackBar,
					isOpen: true,
					message: 'Side tem o valor inválido!',
				});
				return;
			}

			if (isNaN(Number(quantity))) {
				setSnackBar({
					...snackBar,
					isOpen: true,
					message: 'A quantidade precisa ter um valor válido!',
				});
				return;
			}

			if (Number(quantity) <= 0) {
				setSnackBar({
					...snackBar,
					isOpen: true,
					message: 'A quantidade precisa ser maior que zero!',
				});
				return;
			}

			if (price && isNaN(Number(price))) {
				setSnackBar({
					...snackBar,
					isOpen: true,
					message: 'O preço precisa ter um valor válido!',
				});
				return;
			}

			linesForCreate.push({
				symbol,
				side,
				quantity: Number(quantity),
				price: Number(price),
			});
		}

		return linesForCreate;
	}

	async function getInfoSymbol(symbol) {
		try {
			const { data } = await apiEls.get(`/search/${symbol}`);

			if (!data._source) {
				throw new Error(
					`O instrumento ${symbol}, não foi encontrado ou está expirado!`
				);
			}

			if (['DOL', 'WDO'].includes(data._source.asset)) {
				data._source.tickSizeDenominator = 2;
			}
			if (data._source.isContinuous) {
				data._source.symbol = data._source.continuousRef;
			}

			return data._source;
		} catch (error) {
			setSnackBar({ ...snackBar, isOpen: true, message: error.message });
			throw new Error(error.message);
		}
	}

	async function createBulletToPaste() {
		if (navigator.clipboard) {
			navigator.clipboard
				.readText()
				.then(async text => {
					const lines = text.split('\n').filter(item => item !== '');

					const linesForCreate = validateLines(lines);

					let strategyTemplate = {};
					let strategyUpdated = {};
					let strategyCode = '';
					const responsesSymbols = [];

					if (linesForCreate.length > 1) {
						/* Spread */

						strategyCode = 'spread';

						let strategiesLegs = [];

						strategyTemplate = getStrategyTemplate(strategyCode);

						for (let index in linesForCreate) {
							const currentLine = linesForCreate[index];

							responsesSymbols.push(
								getInfoSymbol(currentLine.symbol)
							);

							strategiesLegs.push({
								LegQuantity: currentLine.quantity,
								LegSide: currentLine.side === 'C' ? '1' : '2',
								LegSymbol: currentLine.symbol,
							});
						}

						strategyUpdated = {
							...strategyTemplate,
							StrategyInfos: strategiesLegs.map(() => {
								return {};
							}),
							StrategyCode: `spread${linesForCreate.length}p`,
							StrategyLegs: strategiesLegs,
							context: NEW,
						};
					} else {
						/* Single */

						strategyCode = 'sorder';

						strategyTemplate = getStrategyTemplate(strategyCode);

						const [infoSingle] = linesForCreate;

						responsesSymbols.push(getInfoSymbol(infoSingle.symbol));

						strategyUpdated = {
							...strategyTemplate,
							StrategyInfos: [{}],
							StrategyCode: 'sorder',
							CustomParameters: {
								PriceLimit: infoSingle.price,
							},
							StrategyLegs: [
								{
									LegQuantity: infoSingle.quantity,
									LegSide:
										infoSingle.side === 'C' ? '1' : '2',
									LegSymbol: infoSingle.symbol,
									LegOrdType: '2',
								},
							],
							context: NEW,
						};
					}

					Promise.all(responsesSymbols)
						.then(values => {
							const newStrategyLegs =
								strategyUpdated.StrategyLegs.map(leg => {
									const data = values.find(
										item => item.symbol === leg.LegSymbol
									);

									if (data) {
										if (strategyCode === 'sorder') {
											return leg;
										} else if (strategyCode === 'spread') {
											return {
												...leg,
												LegMaxClipSize: data.roundLot,
												LegMinClipSize: data.roundLot,
											};
										}
									}
								});

							strategyUpdated.StrategyLegs = newStrategyLegs;

							dispatch(
								orderEntry(
									strategyUpdated,
									getSubUid(strategyUpdated)
								)
							);
							dispatch(
								updateStrategyInfosFromApi(
									strategyUpdated.StrategyLegs
								)
							);
							dispatch(additionalUpdate('isOpen', true));
						})
						.catch(error => {
							console.log(error);
						});
				})
				.catch(error => {
					console.log(error);
				});
		}
	}

	useEffect(() => {
		const intervalId = setInterval(() => {
			const newBullet = store.getState().bottomBullet.bullet;
			if (newBullet.isOpen) {
				if (newBullet.content.StrategyCode === 'cvvol') {
					if (newBullet.content.StrategyLegs[0].LegSymbol !== '') {
						if (
							!optInfos?.optionType ||
							optInfos?._id !==
								newBullet.content.StrategyLegs[0].LegSymbol
						) {
							getVolOptInfos(newBullet, 0);
						}
						const strike = optInfos?.strikePrice;
						const tax =
							newBullet.content.CustomParameters.InterestRate;
						const daysUntilMaturity =
							optInfos?.workDaysUntilMaturity;
						const optType =
							optInfos?.optionType === 'CALL' ? 'C' : 'P';
						if (
							newBullet.content.StrategyLegs[0].LegSymbol !==
								'' &&
							daysUntilMaturity !== 0
						) {
							getVolResult(
								strike,
								tax,
								daysUntilMaturity,
								optType,
								newBullet,
								0,
								1
							);
						}
					} else {
						setLastVol({});
						if (
							JSON.stringify(newBullet.StrategyInfos[0]) !==
							JSON.stringify({})
						) {
							dispatch(
								additionalUpdate(
									'StrategyInfos',
									newBullet.content.StrategyLegs.map(() => {
										return {};
									})
								)
							);
						}
					}
				} else if (newBullet.content.StrategyCode === 'skew') {
					if (newBullet.content.StrategyLegs[0].LegSymbol !== '') {
						if (
							!optInfos?.optionType ||
							optInfos?._id !==
								newBullet.content.StrategyLegs[0].LegSymbol
						) {
							getVolOptInfos(newBullet, 0);
						}
						if (
							!secondOptInfos?.optionType ||
							optInfos?._id !==
								newBullet.content.StrategyLegs[1].LegSymbol
						) {
							getVolOptInfos(newBullet, 1);
						}
						const firstStrike = optInfos?.strikePrice;
						const tax =
							newBullet.content.CustomParameters.InterestRate;
						const firstDaysUntilMaturity =
							optInfos?.workDaysUntilMaturity;
						const firstOptType =
							optInfos?.optionType === 'CALL' ? 'C' : 'P';

						if (
							newBullet.content.StrategyLegs[0].LegSymbol !==
								'' &&
							firstDaysUntilMaturity !== 0
						) {
							getVolResult(
								firstStrike,
								tax,
								firstDaysUntilMaturity,
								firstOptType,
								newBullet,
								0,
								2
							);
						}
						const secondStrike = secondOptInfos?.strikePrice;
						const secondDaysUntilMaturity =
							secondOptInfos?.workDaysUntilMaturity;
						const secondOptType =
							secondOptInfos?.optionType === 'CALL' ? 'C' : 'P';

						if (
							newBullet.content.StrategyLegs[1].LegSymbol !==
								'' &&
							secondDaysUntilMaturity !== 0
						) {
							getVolResult(
								secondStrike,
								tax,
								secondDaysUntilMaturity,
								secondOptType,
								newBullet,
								1,
								2
							);
						}

						setLastVol(
							getSkewVolatility(firstResult, secondResult)
						);

						dispatch(setMarketPriceLoading(false));
					} else {
						setLastVol({});
						if (
							JSON.stringify(newBullet.StrategyInfos[0]) !==
							JSON.stringify({})
						) {
							dispatch(
								additionalUpdate(
									'StrategyInfos',
									newBullet.content.StrategyLegs.map(() => {
										return {};
									})
								)
							);
						}
					}
				} else if (
					['spread', 'financ', 'lgshort'].includes(
						newBullet.content.StrategyCode
					) ||
					newBullet.content.StrategyCode.includes('spread')
				) {
					setSpreaAuxPrice(
						newBullet.content.StrategyLegs,
						newBullet.content.CustomParameters.Trigger
					);
				} else if (
					newBullet.content.StrategyLegs.length > 0 &&
					newBullet.content.StrategyLegs[0]?.LegSymbol !== ''
				) {
					if (newBullet.content.StrategyCode !== 'gap') {
						apiMarketData
							.get(
								`/exchanges/BVMF/instruments/${newBullet.content.StrategyLegs[0].LegSymbol}/quotes/last`
							)
							.then(res => {
								if (
									store.getState().bottomBullet.bullet.content
										.StrategyLegs[0].LegSymbol !== ''
								) {
									dispatch(
										updateStrategyInfo(
											{
												lastPx: res.data.lastPx,
												bidPx: res.data.bidPx,
												askPx: res.data.offerPx,
											},
											0
										)
									);
								}
							})
							.catch(error => console.log(error));
					}
				}
			}
		}, 2000);

		return () => clearInterval(intervalId);
	}, []);

	return (
		<>
			<MaterialBulletContainer>
				<Header
					bullet={bullet}
					isListsOpen={isListsOpen}
					isSavedListVisible={isSavedListVisible}
					isRecentListVisible={isRecentListVisible}
					openLists={openLists}
					openBullet={openBullet}
					closeBullet={closeBullet}
					handleBullet={handleBullet}
					createBulletToPaste={createBulletToPaste}
				/>

				<Box
					sx={{
						p: bullet.isOpen || isListsOpen ? 1 : 0,
						pb: bullet.isOpen ? 1 : 0,
						background: colors.grey[800],
					}}
				>
					{bullet.isOpen ? (
						<>
							<BodyBullet
								StrategyCode={bullet.content.StrategyCode}
								execType={validateTypeBullet(
									bullet.content.CustomParameters
										.ExecStrategy,
									newExecType ?? oldExecType,
									bullet.context
								)}
								bullet={bullet}
								setPaperInfo={setPaperInfo}
								setSpreaAuxPrice={setSpreaAuxPrice}
								stepFunction={stepFunction}
								onSuggestionsFetchRequested={
									onSuggestionsFetchRequested
								}
								handleChange={handleChange}
								suggestions={suggestions}
								marketPrice={marketPrice}
								lastVol={lastVol}
							/>
							<MaterialFooter
								closeBullet={closeBullet}
								setLastVol={setLastVol}
							/>
						</>
					) : (
						<>
							{isRecentListVisible && (
								<RecentTable
									formatDate={formatDate}
									openBullet={openBullet}
									handleCloseList={() =>
										setIsRecentListVisible(false)
									}
									setIsStrategyExpired={() =>
										setSnackBar({
											isOpen: true,
											message: t(
												'alerts.snackbars.selectExpiredStrategy'
											),
											severity: 'error',
										})
									}
								/>
							)}

							{isSavedListVisible && (
								<SavedTable
									formatDate={formatDate}
									openBullet={openBullet}
									handleCloseList={() =>
										setIsSavedListVisible(false)
									}
									setIsStrategyExpired={() =>
										setSnackBar({
											isOpen: true,
											message: t(
												'alerts.snackbars.selectExpiredStrategy'
											),
											severity: 'error',
										})
									}
								/>
							)}
						</>
					)}
				</Box>
			</MaterialBulletContainer>

			<MaterialSnackbar
				open={snackBar.isOpen}
				autoHide={2000}
				handleClose={() =>
					setSnackBar({
						severity: 'error',
						message: '',
						isOpen: false,
					})
				}
				severity={snackBar.severity}
			>
				{snackBar.message}
			</MaterialSnackbar>
		</>
	);
};

export default MaterialTabs;
