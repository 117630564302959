import { store } from 'store';

import {
	additionalUpdate,
	orderEntry,
	updateStrategyInfosFromApi,
} from 'store/modules/bottomBullet/actions';
import { getSubUid } from './strategies';

import { apiExec } from 'services/api';

const validationExchangeParameters = parameters => {
	const requiredFields = ['symbol', 'exchange'];

	for (const field of requiredFields) {
		if (!parameters[field]) {
			return {
				message: `Problema ao processar requisição! Campo ${field} inválido!`,
				code: 4433,
			};
		}

		if (
			field === 'symbol' &&
			(!Array.isArray(parameters[field]) ||
				parameters[field].length === 0)
		) {
			return {
				message: `Problema ao processar requisição! Campo ${field} inválido!`,
				code: 4433,
			};
		}
	}

	const exchange = parameters.exchange;
	const symbol = parameters.symbol.join(',');
	const detailed = parameters.detailed ?? false;

	return {
		exchange,
		symbol,
		detailed,
	};
};

export const getResponseAction = async (dataMessage, activeProduct) => {
	const actions = {
		get_permissions: () => {
			const attributes = activeProduct.attributes;

			return {
				strategies: attributes.strategies,
				actions: attributes.actions,
			};
		},
		get_day_positions: async () => {
			const { data } = await apiExec.get('/positions');

			const positions = data.records;

			return positions;
		},
		get_user: () => {
			const user = store.getState().auth;

			const { username, name, email } = user;

			const document = user.cpf ? user.cpf : user.cnpj;

			return {
				username,
				name,
				email,
				document,
			};
		},
		get_strategies_list: async () => {
			const { data } = await apiExec.get('/algos');

			const strategies = data.records;

			return strategies;
		},
		get_definition: async () => {
			const parameters = dataMessage.parameters ?? {};

			const params = validationExchangeParameters(parameters);

			if (params.code && params.message) {
				return {
					statusCode: 400,
					message: params.message,
					code: params.code,
				};
			}

			const { data } = await apiExec.get(
				`/exchanges/${params.exchange}/instruments`,
				{
					params: {
						symbol: params.symbol,
						detailed: params.detailed,
					},
				}
			);

			const definitions = data.records;

			return definitions;
		},
		get_strategy: async () => {
			const parameters = dataMessage.parameters ?? {};

			if (!('clOrdId' in parameters) || !parameters.clOrdId) {
				return {
					statusCode: 400,
					message:
						'Problema ao processar requisição! Campo clOrdId inválido!',
					code: 4433,
				};
			}

			const clOrdId = parameters.clOrdId;

			const { data } = await apiExec.get(`/algos/${clOrdId}`);

			return data;
		},
		get_lastquote: async () => {
			const parameters = dataMessage.parameters ?? {};

			const params = validationExchangeParameters(parameters);

			if (params.code && params.message) {
				return {
					statusCode: 400,
					message: params.message,
					code: params.code,
				};
			}

			const { data } = await apiExec.get(
				`/exchanges/${params.exchange}/quotes/last`,
				{
					params: {
						symbols: params.symbol,
					},
				}
			);

			return data;
		},
		open_bullet: () => {
			const parameters = dataMessage.parameters ?? {};

			// Validando parametros da boleta

			if (!('strategy' in parameters)) {
				return {
					statusCode: 400,
					message:
						'Problema ao processar requisição! Campo strategy inválido!',
					code: 4433,
				};
			}

			const requiredFields = [
				'StrategyCode',
				'CustomParameters',
				'StrategyLegs',
			];

			for (const field of requiredFields) {
				if (!parameters.strategy[field]) {
					return {
						statusCode: 400,
						message: `Problema ao processar requisição! Campo ${field} inválido!`,
						code: 4433,
					};
				}
			}

			const strategy = parameters.strategy;

			const strategyCode = strategy.StrategyCode;

			// Validando se o usuário pode abrir a boleta com a estrategia solicitada
			if (!activeProduct.attributes.strategies.includes(strategyCode)) {
				return {
					statusCode: 403,
					message: 'Sem permissao para acessar o recurso!',
					code: 4432,
				};
			}

			store.dispatch(orderEntry(strategy, getSubUid(strategy)));

			store.dispatch(updateStrategyInfosFromApi(strategy.StrategyLegs));

			store.dispatch(additionalUpdate('isOpen', true));
			store.dispatch(additionalUpdate('refreshNeeded', true));
		},
	};

	const { type, requestId, authToken } = dataMessage;

	const defaultResponse = {
		type,
		requestId,
		authToken,
	};

	if (!actions[type]) {
		return {
			...defaultResponse,
			statusCode: 400,
			response: {
				message: 'Ação inválida!',
				code: 4002,
			},
		};
	}

	try {
		const responseAction = await actions[type]();

		if (
			responseAction &&
			responseAction.code &&
			[400, 403].includes(responseAction.statusCode)
		) {
			return {
				...defaultResponse,
				statusCode: responseAction.statusCode,
				response: {
					message: responseAction.message,
					code: responseAction.code,
				},
			};
		}

		if (responseAction) {
			return {
				...defaultResponse,
				statusCode: 200,
				response: responseAction,
			};
		}

		return {
			...defaultResponse,
			statusCode: 200,
		};
	} catch (error) {
		console.log(error);

		return {
			...defaultResponse,
			statusCode: 500,
			response: {
				message:
					'Problema ao processar a requisição! Tente novamente em instantes.',
				code: 5000,
			},
		};
	}
};
