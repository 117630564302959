import {
	BusinessCenter,
	CloudUploadOutlined,
	SpaceDashboardRounded,
	Workspaces,
	CandlestickChart,
	Today,
	DateRange,
	EventNote,
} from '@mui/icons-material';
import {
	LeftSimulator,
	TemporaryGraph,
	ScannerIcon,
	OptionTrader,
	RiskIcon,
} from 'assets/icons/IconPack';

import { store } from 'store';

export function getApplicationModulesList() {
	const activeProduct = store.getState().products?.activeProduct ?? {};

	let activeModules = activeProduct?.attributes?.modules ?? [];

	const riskEnabled = store.getState().auth?.riskEnabled ?? false;

	const defaultModules = [
		{
			path: '/dashboard',
			label: 'Acompanhamento',
			value: 'current_strategies',
			icon: SpaceDashboardRounded,
		},
		{
			path: '/posicoes',
			label: 'Posições',
			value: 'positions',
			icon: BusinessCenter,
		},
		{
			path: '/simulator',
			label: 'Simulador',
			value: 'simulator',
			icon: TemporaryGraph,
		},
		{
			path: '/cadeia-opcoes',
			label: 'Options Trader',
			value: 'options_trader',
			icon: OptionTrader,
		},
		{
			path: '/volstudio',
			label: 'Vol. Studio',
			value: 'volstudio',
			icon: LeftSimulator,
		},
		{
			path: '/scanner',
			label: 'Scanner',
			value: 'scanner',
			icon: ScannerIcon,
		},
		{
			path: '/riscos',
			label: 'Limite Operacional',
			value: 'risk',
			icon: RiskIcon,
		},
		{
			path: '/upload-estrategias',
			label: 'Upload de estratégias',
			value: 'strategies_upload',
			icon: CloudUploadOutlined,
		},
		{
			path: '/idxlivecharts',
			label: 'Super GL',
			value: 'idx_live_charts',
			icon: CandlestickChart,
		},
	];

	let finalModulesList = [...defaultModules];

	if (activeProduct.isPartnerProduct) {
		if (activeProduct.code === 'TRADE_CERTO') {
			const tradeModules = [
				{
					path: '/external-product/back-tests/b3-daily',
					value: 'external-product_b3-daily',
					label: 'B3 - Timeframe Diário',
					icon: Today,
				},
				{
					path: '/external-product/back-tests/b3-intraday',
					value: 'external-product_b3-intraday',
					label: 'B3 - Timeframe Intraday',
					icon: DateRange,
				},
				{
					path: '/external-product/back-tests/bmf-intraday',
					value: 'external-product_bmf-intraday',
					label: 'BMF - Timeframe Intraday',
					icon: EventNote,
				},
			];

			finalModulesList = [...finalModulesList, ...tradeModules];

			// Adição para que os modulos do trade certo estejam visiveis no menu
			activeModules = [
				...activeModules,
				...tradeModules.map(item => item.value),
			];
		} else {
			const externalModule = {
				path: '/external-product',
				value: 'external-product',
				label: activeProduct.name,
				icon: Workspaces,
			};

			finalModulesList = [...finalModulesList, externalModule];
		}
	}

	if (activeModules.includes('all')) {
		if (!riskEnabled) {
			return finalModulesList.filter(module => module.value !== 'risk');
		}
		return finalModulesList;
	}

	const filteredModules = finalModulesList.filter(module => {
		if (module.value === 'risk' && !riskEnabled) {
			return false;
		} else {
			return activeModules.includes(module.value);
		}
	});

	return filteredModules;
}
