import { format } from 'date-fns';
import { toast } from 'react-toastify';

function getFormattedCustomParameters(customParameters) {
	const formattedCustomParameters = {};

	let targetPriceFields = {};
	let timeFields = {};
	let exitFields = {};

	for (const [key, value] of Object.entries(customParameters)) {
		if (
			![null, undefined, NaN].includes(customParameters[key]) &&
			!String(key).startsWith('_')
		) {
			formattedCustomParameters[key] = value;
		}
	}

	if (formattedCustomParameters.TargetPrice === 'F') {
		targetPriceFields = {
			FixedPrice: formattedCustomParameters.FixedPrice,
		};
	}

	if (formattedCustomParameters.TargetPrice === 'R') {
		targetPriceFields = {
			RelativeType: formattedCustomParameters.RelativeType,
			RelativeDirection: formattedCustomParameters.RelativeDirection,
			RelativeUnit: formattedCustomParameters.RelativeUnit,
			RelativePxRel: formattedCustomParameters.RelativePxRel,
		};
	}

	if (formattedCustomParameters.PxInitTimeMonitoring) {
		timeFields.PxInitTimeMonitoring = format(
			formattedCustomParameters.PxInitTimeMonitoring,
			'HH:mm:ss'
		);

		if (formattedCustomParameters.PxEndTimeMonitoring) {
			timeFields.PxEndTimeMonitoring = format(
				formattedCustomParameters.PxEndTimeMonitoring,
				'HH:mm:ss'
			);
		}
	}

	if (formattedCustomParameters.ExitPxRelLoss) {
		exitFields.ExitPxRelLoss = formattedCustomParameters.ExitPxRelLoss;
		exitFields.ExitUnit = formattedCustomParameters.ExitUnit;
	}

	if (formattedCustomParameters.ExitPxRelGain) {
		exitFields.ExitPxRelGain = formattedCustomParameters.ExitPxRelGain;
		exitFields.ExitUnit = formattedCustomParameters.ExitUnit;
	}

	if (formattedCustomParameters.ExitPxSlippagePts) {
		exitFields.ExitPxSlippagePts =
			formattedCustomParameters.ExitPxSlippagePts;
		exitFields.ExitPxRelLoss = formattedCustomParameters.ExitPxRelLoss;
		exitFields.ExitUnit = formattedCustomParameters.ExitUnit;
	}

	if (formattedCustomParameters.ExitOnEndTime === 'Y') {
		exitFields.ExitOnEndTime = formattedCustomParameters.ExitOnEndTime;
	}

	if (formattedCustomParameters.ExitOnEndDayAuction === 'Y') {
		exitFields.ExitOnEndDayAuction =
			formattedCustomParameters.ExitOnEndDayAuction;
	}

	return {
		TargetPrice: formattedCustomParameters.TargetPrice,
		EntranceMoment: 'I',
		...targetPriceFields,
		...timeFields,
		...exitFields,
	};
}

function verifyEmptyMandatoryFields(objectToVerify) {
	const possibleMandatoryFieldsMapping = {
		TargetPrice: 'Preço alvo',
		ExitPxSlippagePts: 'Slippage',
		ExitPxRelLoss: 'Loss',
		ExitUnit: 'Seletor de pontos ou porcentagem da saída',
		ExitPxRelGain: 'Gain',
		FixedPrice: 'Preço fixo',
		RelativeDirection: 'Direção relativa',
		RelativeType: 'Tipo relativo',
		RelativeUnit: 'Unidade relativa',
		RelativePxRel: 'Preço relativo',
	};

	let emptyFieldForMessage;

	const entryConfigMandatoryParams = {
		F: ['FixedPrice'],
		R: [
			'RelativeDirection',
			'RelativeType',
			'RelativeUnit',
			'RelativePxRel',
		],
	};

	const exitMandatoryParams = [];

	if (objectToVerify.ExitPxRelLoss) {
		exitMandatoryParams.push('ExitPxRelLoss', 'ExitUnit');
	}

	if (objectToVerify.ExitPxRelGain) {
		exitMandatoryParams.push('ExitPxRelGain', 'ExitUnit');
	}

	if (objectToVerify.ExitPxSlippagePts) {
		exitMandatoryParams.push(
			'ExitPxSlippagePts',
			'ExitPxRelLoss',
			'ExitUnit'
		);
	}

	const mandatoryCustomParameterFields = [
		'TargetPrice',
		...(entryConfigMandatoryParams[objectToVerify.TargetPrice] || []),
		...exitMandatoryParams,
	];

	const hasCustomParameterFieldEmpty = mandatoryCustomParameterFields.some(
		field => {
			if ([null, undefined, ''].includes(objectToVerify[field])) {
				emptyFieldForMessage = field;
				return true;
			}
			return false;
		}
	);

	if (hasCustomParameterFieldEmpty) {
		toast.error(
			`Campo obrigatório "${possibleMandatoryFieldsMapping[emptyFieldForMessage]}" não preenchido`
		);

		return true;
	}

	return false;
}

function getFormattedStrategyLegs(bullet, account) {
	const strategyLeg = bullet.StrategyLegs[0];

	return [
		{
			ILegAllocAccount: account,
			LegMaxClipSize: strategyLeg.LegMaxClipSize,
			LegMinClipSize: strategyLeg.LegMinClipSize,
			LegQuantity: strategyLeg.LegQuantity,
			LegSecurityExchange: strategyLeg.LegSecurityExchange,
			LegSide: strategyLeg.LegSide,
			LegSymbol: strategyLeg.LegSymbol,
		},
	];
}

export function gapClear(bullet, account) {
	const { content } = bullet;

	const hasMandatoryFieldEmpty = verifyEmptyMandatoryFields(
		content.CustomParameters
	);

	if (hasMandatoryFieldEmpty) {
		return;
	}

	const formattedCustomParameters = getFormattedCustomParameters(
		content.CustomParameters
	);

	const accountToBeUsed = account();

	const strategyLegs = getFormattedStrategyLegs(content, accountToBeUsed);

	return {
		Name: content.Name,
		InitTime: content.InitTime,
		EndTime: content.EndTime,
		ExpireDate: content.ExpireDate,
		BasketId: content.BasketId,
		StrategyCode: content.StrategyCode,
		CustomParameters: formattedCustomParameters,
		StrategyLegs: strategyLegs,
		Signature: content.Signature,
	};
}
