import { getUserGlobalStop } from 'helpers/gradient/customParameters';
import { toast } from 'react-toastify';
import { store } from 'store';
import { additionalUpdate } from 'store/modules/bottomBullet/actions';
import { setGlobalSettingsModule, setIsGlobalSettingsOpen } from 'store/modules/panelConfig/actions';
import { PRODUCT_TYPES } from 'utils/constants';

const clearDinCustom = custom => {
	const clearCustom = {
		SelectStopByPrice2: custom.StopByPrice2 ? 1 : 0,
		IncrementPriceStopGain: custom.IncrementPriceStopGain,
		IncrementPriceStepEntrance: custom.IncrementPriceStepEntrance,
		UseRetroaliment: custom.UseRetroaliment,
		EntrancePrice: custom.EntrancePrice,
		UseStartWhenCross: custom.UseStartWhenCross,
		SendStopLossOnEnd: custom.SendStopLossOnEnd,
		UsePreservation: custom.UsePreservation,
		Started: custom.Started || 'N',
		StopLossFinance: custom.StopLossFinance,
		NumberOfEntrances: custom.NumberOfEntrances,
		OrderQuantity: custom.OrderQuantity,
		StopByPrice1: custom.StopByPrice1,
		StopByPrice2: custom.StopByPrice2,
		SelectStopByPrice1: custom.StopByPrice1 ? 1 : 0,
		BypassFirewall: custom.BypassFirewall || 'N',
	};

	const startWhenCross =
		custom.StartWhenCross || custom.NumberOfMinPriceIncrementsToStart;

	if (clearCustom.UseStartWhenCross === 'Y') {
		clearCustom.StartWhenCross = parseFloat(startWhenCross);
	} else {
		clearCustom.NumberOfMinPriceIncrementsToStart =
			parseFloat(startWhenCross);
	}
	if (custom.UseRetroaliment === 'Y') {
		clearCustom.MaximumZeroTimeWindow = custom.MaximumZeroTimeWindow;
		clearCustom.RetroalimentType = custom.RetroalimentType;
		clearCustom.RetroalimentParam1 = custom.RetroalimentParam1;
		if (
			clearCustom.RetroalimentType !== 5 &&
			clearCustom.RetroalimentType !== 4
		) {
			clearCustom.RetroalimentTimeWindow = custom.RetroalimentTimeWindow;
		}
	}
	if (custom.UsePreservation === 'Y') {
		clearCustom.PreservationPercentual = custom.PreservationPercentual;
		clearCustom.PreservationStartTrigger = custom.PreservationStartTrigger;
		clearCustom.PreservationStep = 10;
	}
	if (clearCustom.SelectStopByPrice1 === 0) {
		delete clearCustom.StopByPrice1;
	}
	if (clearCustom.SelectStopByPrice2 === 0) {
		delete clearCustom.StopByPrice2;
	}
	if (custom.StopGainGlobalFinance) {
		clearCustom.StopGainGlobalFinance = custom.StopGainGlobalFinance;
		clearCustom.UseStopGainGlobalFinance = 'Y';
	}

	return clearCustom;
};

const gradientClear = async (
	bullet,
	setAccessorInfos,
	account,
	t,
	dispatch
) => {
	const hash = { ...bullet.content };
	const strategyInfos = { ...bullet.StrategyInfos };
	if (process.env.NODE_ENV === 'development') console.log('hashtag', hash);
	if (hash.StrategyLegs.some(leg => leg.LegSymbol === '')) {
		toast.error(t('Select all symbols'));
		return;
	}

	if (
		!bullet.content.CustomParameters.EntrancePrice ||
		bullet.content.CustomParameters.EntrancePrice === 0
	) {
		if (bullet.isMarketPrice) {
			toast.error(
				'Não foi possível definir um valor de início de range a mercado, devido as condições atuais do papel. Tente novamente em instantes.'
			);
			return;
		}

		dispatch(additionalUpdate('errorEntrancePrice', true));
		toast.error(t('Preencha o campo de Preço início do range'));
		return;
	}

	if (
		!bullet.content.CustomParameters.StopLossFinance ||
		bullet.content.CustomParameters.StopLossFinance === 0
	) {
		dispatch(additionalUpdate('errorStopLossFinance', true));
		toast.error(t('Preencha o campo de Stop Loss Financeiro'));
		return;
	}
	if (bullet.content.CustomParameters.UseStartWhenCross === 0) {
		toast.error(
			'O campo iniciar a estratégia é obrigatório, selecione uma opção'
		);
		return;
	}

	if (bullet.subUid !== 3) {
		hash.StrategyCode =
			hash.CustomParameters.UseRetroaliment === 'Y' ? 'grddin' : 'grdlin';
	}

	if (
		hash.CustomParameters.SelectStopByPrice1 === 1 &&
		!hash.CustomParameters.StopByPrice1
	) {
		dispatch(additionalUpdate('errorStopByPrice1', true));
		toast.error(t('Preencha o campo de Stop Loss por preço'));
		return;
	}
	if (
		hash.CustomParameters.SelectStopByPrice2 === 1 &&
		!hash.CustomParameters.StopByPrice2
	) {
		dispatch(additionalUpdate('errorStopByPrice2', true));
		toast.error(t('Preencha o campo de Stop Gain por preço'));
		return;
	}
	if (hash.StrategyLegs[0].LegSide === '1') {
		if (
			hash.CustomParameters.SelectStopByPrice1 === 1 &&
			hash.CustomParameters.StopByPrice1 >
				hash.CustomParameters.EntrancePrice
		) {
			dispatch(additionalUpdate('errorStopByPrice1', true));
			toast.error(t('Stop Loss por preço é inconsistente'));
			return;
		}
		if (
			hash.CustomParameters.SelectStopByPrice2 === 1 &&
			hash.CustomParameters.StopByPrice2 <
				hash.CustomParameters.EntrancePrice
		) {
			dispatch(additionalUpdate('errorStopByPrice2', true));
			toast.error(t('Stop Gain por preço é inconsistente'));
			return;
		}
	} else {
		if (
			hash.CustomParameters.SelectStopByPrice1 === 1 &&
			hash.CustomParameters.StopByPrice1 <
				hash.CustomParameters.EntrancePrice
		) {
			dispatch(additionalUpdate('errorStopByPrice1', true));
			toast.error(t('Stop Loss por preço é inconsistente'));
			return;
		}
		if (
			hash.CustomParameters.SelectStopByPrice2 === 1 &&
			hash.CustomParameters.StopByPrice2 >
				hash.CustomParameters.EntrancePrice
		) {
			dispatch(additionalUpdate('errorStopByPrice2', true));
			toast.error(t('Stop Gain por preço é inconsistente'));
			return;
		}
	}
	if (
		hash.CustomParameters.UseStopGainGlobalFinance === 'Y' &&
		!hash.CustomParameters.StopGainGlobalFinance
	) {
		dispatch(additionalUpdate('errorStopGainGlobalFinance', true));
		toast.error(t('Preencha o campo de Stop Gain financeiro'));
		return;
	}
	hash.CustomParameters = clearDinCustom(hash.CustomParameters);

	const accountToBeUsed = account();

	hash.StrategyLegs[0] = {
		ILegAllocAccount: accountToBeUsed,
		LegSymbol: hash.StrategyLegs[0].LegSymbol,
		LegSecurityExchange: strategyInfos[0].subExchange,
		LegSide: hash.StrategyLegs[0].LegSide,
		LegTimeInForce: 0,
		LegRatioQuantity: 1,
	};

	delete hash.clean;
	hash.StrategyLegs = setAccessorInfos(hash.StrategyLegs);

	const activeProduct = store.getState().products.activeProduct;

	const { FLEX_PRO, FLEX_ADVANCED } = PRODUCT_TYPES;

	const hasValidPrefix = ['WIN', 'WDO'].some(prefix =>
		String(hash.StrategyLegs[0].LegSymbol).startsWith(prefix)
	);

	let globalStopId = {};

	if (
		hasValidPrefix &&
		[FLEX_PRO, FLEX_ADVANCED].includes(activeProduct?.code)
	) {
		const globalStop = await getUserGlobalStop();
		const reduxGlobalStop = store.getState().idxLiveCharts.gradientStop;

		if (globalStop) {
			if (globalStop.acumFinTarget > reduxGlobalStop.finLimitGain) {
				toast.error(
					'Stop Gain Global atingido, a estratégia não poderá ser enviada!'
				);
				return;
			}

			if (globalStop.acumFinTarget < reduxGlobalStop.finLimitLoss) {
				toast.error(
					'Stop Loss Global atingido, a estratégia não poderá ser enviada!'
				);
				return;
			}

			if (globalStop.finLimitGain === 0 && globalStop.finLimitLoss === 0) {
				dispatch(setIsGlobalSettingsOpen(true));
				dispatch(setGlobalSettingsModule('gradient'));
				toast.error(
					'Você precisa definir os limites globais de Gain e Loss, que todas as estratégias de gradiente que você executar estarão sujeitas simultaneamente.'
				);
				return;
			}

			globalStopId = { GlobalStopId: globalStop.id };
		} else {
			console.error('Stop global não encontrado.');
			globalStopId = { GlobalStopId: reduxGlobalStop.id };
		}
	}

	return {
		Name: hash.Name,
		InitTime: hash.InitTime,
		EndTime: hash.EndTime,
		ExpireDate: hash.ExpireDate,
		BasketId: hash.BasketId,
		StrategyCode: hash.StrategyCode,
		CustomParameters: hash.CustomParameters,
		StrategyLegs: [hash.StrategyLegs[0]],
		Signature: hash.Signature,
		...globalStopId,
	};
};

export default gradientClear;
