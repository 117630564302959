import styled from 'styled-components';

import { Grid } from '@material-ui/core';

export const Container = styled(Grid)`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: left;

	width: 49rem;

	img {
		width: 100%;
		border-radius: 5px;
	}
`;

export const SubmitBox = styled(Grid)`
	display: flex;
	justify-content: space-between;
	align-items: center;

	width: 100%;
	margin-top: 16px;

	button {
		width: 100px !important;
	}
`;
