import React from 'react';

import { Chip, colors } from '@mui/material';

import { getFormattedDate } from 'utils/getParsedDate';

export const recentColumns = [
	{
		field: 'Name',
		headerName: 'Nome da estratégia',
		flex: 1,
	},
	{
		field: 'date',
		headerName: 'Data/Hora de envio',
		type: 'date',
		flex: 1,
		renderCell: params =>
			`${getFormattedDate(params.value, 'dd/MM/yyyy')} ${
				params.row.time
			}`,
	},
	{
		field: 'TimeInForce',
		headerName: 'Validade',
		type: 'string',
		flex: 1,
		renderCell: params => {
			const timeInForceStyles = {
				0: { color: '#3FD293', label: 'DIA' },
				1: { color: '#c54fff', label: 'VAC' },
				6: { color: colors.blue[500], label: 'DES' },
			};

			const { color, label } = timeInForceStyles[params.value] || {};

			return (
				<Chip
					size="small"
					label={label ?? ''}
					sx={{
						px: 0.5,
						fontWeight: 'bold',
						backgroundColor: color ? `${color}30` : '',
						color: color || '',
					}}
				/>
			);
		},
	},
	{
		flex: 1,
		field: 'ExpireDate',
		headerName: 'Data vencimento',
		type: 'date',
		renderCell: params => {
			if (params.row.TimeInForce === '1') {
				return '';
			}

			return getFormattedDate(params.value, 'dd/MM/yyyy');
		},
	},
];
