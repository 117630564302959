// import { toast } from 'react-toastify';
import { apiExec } from 'services/api';
import { store } from 'store';
import { PRODUCT_TYPES } from 'utils/constants';

function getInitialNumberOfEntrances(asset) {
	if (String(asset).toUpperCase() === 'WIN') {
		return 10;
	}

	if (String(asset).toUpperCase() === 'WDO') {
		return 6;
	}

	return 1;
}

function getInitialIncrementPriceStepEntrance(
	asset,
	minPriceIncrement,
	prevClosePx
) {
	const upperAsset = String(asset).toUpperCase();

	if (['WIN', 'WDO'].includes(upperAsset)) {
		const result = prevClosePx * 0.001;
		return Math.floor(result / minPriceIncrement) * minPriceIncrement;
	}

	return minPriceIncrement * 2;
}

function getRetroAlimentValue(asset, minPriceIncrement) {
	if (String(asset).toUpperCase() === 'WDO') {
		return 0.25;
	}

	return minPriceIncrement;
}

export function getEntrancePriceInitialValue(
	legSide,
	asset,
	prevClosePx,
	stepSize
) {
	if (String(asset).toUpperCase() === 'WDO') {
		if (legSide === '1') {
			return (
				Math.round((prevClosePx - prevClosePx * 0.007) / stepSize) *
				stepSize
			);
		}

		if (legSide === '2') {
			return (
				Math.round((prevClosePx + prevClosePx * 0.007) / stepSize) *
				stepSize
			);
		}
	}

	if (String(asset).toUpperCase() === 'WIN') {
		if (legSide === '1') {
			return (
				Math.round((prevClosePx - prevClosePx * 0.0082) / stepSize) *
				stepSize
			);
		}

		if (legSide === '2') {
			return (
				Math.round((prevClosePx + prevClosePx * 0.0082) / stepSize) *
				stepSize
			);
		}
	}

	return null;
}

export function getDepletionGradientInitialValues(
	symbolData,
	legSide,
	isMarketPrice,
	stepSize
) {
	const incrementPrice = getInitialIncrementPriceStepEntrance(
		symbolData.asset,
		symbolData.minPriceIncrement,
		symbolData.prevClosePx || 0
	);

	let entrancePrice = {};

	if (!isMarketPrice) {
		const calculatedEntrancePrice = getEntrancePriceInitialValue(
			legSide,
			symbolData.asset,
			symbolData.prevClosePx,
			stepSize
		);

		entrancePrice = { EntrancePrice: calculatedEntrancePrice };
	}

	return {
		NumberOfEntrances: getInitialNumberOfEntrances(symbolData.asset),
		IncrementPriceStepEntrance: incrementPrice,
		IncrementPriceStopGain: incrementPrice,
		NumberOfMinPriceIncrementsToStart: 1000,
		MaximumZeroTimeWindow: 15,
		SendStopLossOnEnd: 'Y',
		UseRetroaliment: 'Y',
		RetroalimentType: 5,
		RetroalimentParam1: getRetroAlimentValue(
			symbolData.asset,
			symbolData.minPriceIncrement
		),
		...entrancePrice,
	};
}

export function getGradientFormattedName(isMarketPrice, name) {
	if (isMarketPrice) {
		if (!name.startsWith('GDM-')) {
			return `GDM-${name}`;
		}

		return name;
	}

	if (!name.startsWith('GDE-')) {
		return `GDE-${name}`;
	}

	return name;
}

export function getGlobalStopId(legSymbol) {
	const activeProduct = store.getState().products.activeProduct;

	const { FLEX_PRO, FLEX_ADVANCED } = PRODUCT_TYPES;

	const hasValidPrefix = ['WIN', 'WDO'].some(prefix =>
		String(legSymbol).startsWith(prefix)
	);

	if (
		hasValidPrefix &&
		[FLEX_PRO, FLEX_ADVANCED].includes(activeProduct?.code)
	) {
		const globalStopId = store.getState().idxLiveCharts.gradientStop?.id;

		if (globalStopId) {
			return { GlobalStopId: globalStopId };
		}
	}

	return {};
}

export async function getUserGlobalStop() {
	try {
		const { data } = await apiExec.get('/globalstops', {
			params: {
				realtime: true,
			},
		});

		const globalStop = data.records[0];

		return globalStop;
	} catch (error) {
		console.error(error);
		throw new Error('Error on getting global stops');
	}
}
