import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
	Box,
	Button,
	OutlinedInput,
	Typography,
	Divider,
	List,
	ListItem,
	colors,
	ListItemText,
	Collapse,
} from '@mui/material';

import { ModalTemplate } from '../../AppHeader/Modals/Template';
import { setModalPreference } from 'store/modules/userPreferences/actions';
import { apiAWS } from 'services/api';
import { doLogout } from 'store/modules/auth/actions';
import { PRODUCT_TYPES } from 'utils/constants';
import { toast } from 'react-toastify';

export const ChangeProduct = ({ open }) => {
	const { username } = useSelector(state => state.auth);

	const dispatch = useDispatch();

	const { activeProduct, products } = useSelector(state => state.products);

	const [selectedProduct, setSelectedProduct] = useState('');

	const [step, setStep] = useState(0);

	const [signature, setSignature] = useState('');

	const handleConfirm = () => {
		if (signature.length >= 6) {
			changeProduct(selectedProduct);
		} else {
			toast.error('Assinatura tem que ter pelo menos 6 caracteres.');
		}
	};

	const changeProduct = product => {
		apiAWS
			.post('/subscriptions', {
				userName: username,
				productCode: [product],
				signature: btoa(signature),
			})
			.then(() => {
				setStep(2);
			})
			.catch(error => {
				console.log(error);
				toast.error(
					error?.response?.data?.title ??
						'Problema ao atualizar produto. Tente novamente em instantes.'
				);
			});
	};

	const standardLabel =
		activeProduct.code === PRODUCT_TYPES.FLEX_STANDARD
			? 'Atual'
			: activeProduct.code === PRODUCT_TYPES.FLEX_ANALYTIC
				? 'Upgrade'
				: 'Ver na corretora';

	const advancedLabel =
		activeProduct.code === PRODUCT_TYPES.FLEX_ADVANCED
			? 'Atual'
			: activeProduct.code !== PRODUCT_TYPES.FLEX_PRO
				? 'Upgrade'
				: 'Ver na corretora';

	return (
		<ModalTemplate
			onClose={() =>
				dispatch(setModalPreference('isChangeProductOpen', false))
			}
			open={open}
			title="Assinaturas"
		>
			{step === 0 && (
				<Box
					sx={{
						display: 'flex',
						gap: 4,
						p: 2,
					}}
				>
					<Box
						sx={{
							gap: 1,
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'space-between',
							borderRadius: 1,
							border: '2px solid',
							borderColor:
								activeProduct.code ===
								PRODUCT_TYPES.FLEX_ANALYTIC
									? colors.blue[500]
									: colors.grey[500],
							padding: 2,
							minWidth: 250,
						}}
					>
						<Box>
							<Typography variant="h5" textAlign="center">
								Analítico
							</Typography>

							<Divider sx={{ mt: 1 }} />
						</Box>

						<Box
							sx={{
								display: 'flex',
								flexDirection: 'column',
								flex: 1,
							}}
						>
							<List disablePadding>
								<ListItem disablePadding>
									Informações de Ativos
								</ListItem>

								<ListItem sx={{ mt: 2 }} disablePadding>
									Módulos:
								</ListItem>
								<Collapse in={true}>
									<List
										disablePadding
										disableGutters
										sx={{ pl: 1 }}
									>
										<ListItem disablePadding>
											<ListItemText
												sx={{ color: colors.grey[400] }}
											>
												• Scanner
											</ListItemText>
										</ListItem>
										<ListItem disablePadding>
											<ListItemText
												sx={{ color: colors.grey[400] }}
											>
												• Simulador
											</ListItemText>
										</ListItem>
										<ListItem disablePadding>
											<ListItemText
												sx={{ color: colors.grey[400] }}
											>
												• Vol Studio
											</ListItemText>
										</ListItem>
										<ListItem disablePadding>
											<ListItemText
												sx={{ color: colors.grey[400] }}
											>
												• Option Trader
											</ListItemText>
										</ListItem>
									</List>
								</Collapse>
							</List>
						</Box>

						<Button
							variant="contained"
							disabled
							onClick={() => {
								setStep(1);
								setSelectedProduct('FLEX_STANDARD');
							}}
						>
							{activeProduct.code === PRODUCT_TYPES.FLEX_ANALYTIC
								? 'Atual'
								: 'Ver na corretora'}
						</Button>
					</Box>

					<Box
						sx={{
							gap: 1,
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'space-between',
							borderRadius: 1,
							border: '2px solid',
							borderColor:
								activeProduct.code ===
								PRODUCT_TYPES.FLEX_STANDARD
									? colors.blue[500]
									: colors.grey[500],
							padding: 2,
							minWidth: 250,
						}}
					>
						<Box>
							<Typography variant="h5" textAlign="center">
								Standard
							</Typography>

							<Divider sx={{ mt: 1 }} />
						</Box>

						<Box
							sx={{
								display: 'flex',
								flexDirection: 'column',
								flex: 1,
							}}
						>
							<List disablePadding>
								<ListItem disablePadding>
									Tudo do analítico, mais:
								</ListItem>
								<ListItemText
									sx={{ pl: 3, color: colors.grey[400] }}
								>
									• Execução de até 30 estratégias em
									simultâneo (para única conta)
								</ListItemText>

								<ListItem disablePadding>Estratégias:</ListItem>
								<Collapse in={true}>
									<List
										disablePadding
										disableGutters
										sx={{ pl: 1 }}
									>
										<ListItem disablePadding>
											<ListItemText
												sx={{ color: colors.grey[400] }}
											>
												• Single Limitada, Mercado,
												Start com parâmetros para
												entrada e saída
											</ListItemText>
										</ListItem>
										<ListItem disablePadding>
											<ListItemText
												sx={{ color: colors.grey[400] }}
											>
												• Spreads de 2, 3 e 4 pontas
											</ListItemText>
										</ListItem>
										<ListItem disablePadding>
											<ListItemText
												sx={{ color: colors.grey[400] }}
											>
												• Financiamento
											</ListItemText>
										</ListItem>
										<ListItem disablePadding>
											<ListItemText
												sx={{ color: colors.grey[400] }}
											>
												• Long&Short (entre ativos) com
												pescaria e parametrizável
											</ListItemText>
										</ListItem>
										<ListItem disablePadding>
											<ListItemText
												sx={{ color: colors.grey[400] }}
											>
												• Gradiente
											</ListItemText>
										</ListItem>
									</List>
								</Collapse>
							</List>
						</Box>

						<Button
							variant="contained"
							disabled={
								activeProduct.code !==
								PRODUCT_TYPES.FLEX_ANALYTIC
							}
							onClick={() => {
								setStep(1);
								setSelectedProduct('FLEX_STANDARD');
							}}
						>
							{standardLabel}
						</Button>
					</Box>

					<Box
						sx={{
							gap: 1,
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							borderRadius: 1,
							border: '2px solid',
							borderColor:
								activeProduct.code ===
								PRODUCT_TYPES.FLEX_ADVANCED
									? colors.blue[500]
									: colors.grey[500],
							padding: 2,
							minWidth: 250,
						}}
					>
						<Box>
							<Typography variant="h5" textAlign="center">
								Advanced
							</Typography>

							<Divider sx={{ mt: 1 }} />
						</Box>

						<Box
							sx={{
								display: 'flex',
								flexDirection: 'column',
								flex: 1,
							}}
						>
							<List disablePadding>
								<ListItem disablePadding>
									Tudo do standard, mais:
								</ListItem>
								<Collapse in={true}>
									<List disablePadding sx={{ pl: 1 }}>
										<ListItemText
											sx={{ color: colors.grey[400] }}
										>
											• Execução de até 50 estratégias em
											simultâneo (para única conta)
										</ListItemText>

										<ListItemText
											sx={{ color: colors.grey[400] }}
										>
											• Stop Global, com gain e loss para
											gradientes WIN e WDO.
										</ListItemText>
									</List>
								</Collapse>

								<ListItem disablePadding>Estratégias:</ListItem>
								<Collapse in={true}>
									<List
										disablePadding
										disableGutters
										sx={{ pl: 1 }}
									>
										<ListItem disablePadding>
											<ListItemText
												sx={{ color: colors.grey[400] }}
											>
												• Spreads de 5 e 6 pontas
											</ListItemText>
										</ListItem>
										<ListItem disablePadding>
											<ListItemText
												sx={{ color: colors.grey[400] }}
											>
												• MIT
											</ListItemText>
										</ListItem>
										<ListItem disablePadding>
											<ListItemText
												sx={{ color: colors.grey[400] }}
											>
												• Sniper
											</ListItemText>
										</ListItem>
										<ListItem disablePadding>
											<ListItemText
												sx={{ color: colors.grey[400] }}
											>
												• BestOffer
											</ListItemText>
										</ListItem>
										<ListItem disablePadding>
											<ListItemText
												sx={{ color: colors.grey[400] }}
											>
												• Gradiente esgotamento
											</ListItemText>
										</ListItem>
									</List>
								</Collapse>

								<ListItem disablePadding>Módulos:</ListItem>
								<Collapse in={true}>
									<List disablePadding sx={{ pl: 1 }}>
										<ListItem disablePadding>
											<ListItemText
												sx={{ color: colors.grey[400] }}
											>
												• Super GL(Módulo de gradientes)
											</ListItemText>
										</ListItem>
									</List>
								</Collapse>
							</List>
						</Box>

						<Button
							variant="contained"
							disabled={[
								PRODUCT_TYPES.FLEX_ADVANCED,
								PRODUCT_TYPES.FLEX_PRO,
							].includes(activeProduct.code)}
							onClick={() => {
								setStep(1);
								setSelectedProduct('FLEX_ADVANCED');
							}}
						>
							{advancedLabel}
						</Button>
					</Box>

					<Box
						sx={{
							gap: 1,
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'space-between',
							borderRadius: 1,
							border: '2px solid',
							borderColor:
								activeProduct.code === PRODUCT_TYPES.FLEX_PRO
									? colors.blue[500]
									: colors.grey[500],
							padding: 2,
							minWidth: 250,
						}}
					>
						<Box>
							<Typography variant="h5" textAlign="center">
								Professional
							</Typography>

							<Divider sx={{ mt: 1 }} />
						</Box>

						<Box
							sx={{
								display: 'flex',
								flexDirection: 'column',
								flex: 1,
							}}
						>
							<List disablePadding>
								<ListItem disablePadding>
									Tudo do advanced, mais:
								</ListItem>
								<Collapse in={true}>
									<List disablePadding sx={{ pl: 1 }}>
										<ListItemText
											sx={{ color: colors.grey[400] }}
										>
											• Execução de até 100 estratégias em
											simultâneo (para múltiplas contas)
										</ListItemText>

										<ListItemText
											sx={{ color: colors.grey[400] }}
										>
											• APIs para execução e
											acompanhamento de estratégias
										</ListItemText>
									</List>
								</Collapse>

								<ListItem disablePadding>Estratégias:</ListItem>
								<Collapse in={true}>
									<List disablePadding sx={{ pl: 1 }}>
										<ListItem disablePadding>
											<ListItemText
												sx={{ color: colors.grey[400] }}
											>
												• TWAP
											</ListItemText>
										</ListItem>
										<ListItem disablePadding>
											<ListItemText
												sx={{ color: colors.grey[400] }}
											>
												• Balanceamento de carteira
											</ListItemText>
										</ListItem>
										<ListItem disablePadding>
											<ListItemText
												sx={{ color: colors.grey[400] }}
											>
												• Compra e venda de volatilidade
											</ListItemText>
										</ListItem>
										<ListItem disablePadding>
											<ListItemText
												sx={{ color: colors.grey[400] }}
											>
												• Skew de volatilidade
											</ListItemText>
										</ListItem>
									</List>
								</Collapse>

								<ListItem disablePadding>Módulos:</ListItem>
								<Collapse in={true}>
									<List disablePadding sx={{ pl: 1 }}>
										<ListItem disablePadding>
											<ListItemText
												sx={{ color: colors.grey[400] }}
											>
												• Upload de estratégias via
												arquivo
											</ListItemText>
										</ListItem>
									</List>
								</Collapse>
							</List>
						</Box>

						<Button
							variant="contained"
							disabled={
								activeProduct.code === PRODUCT_TYPES.FLEX_PRO
							}
							onClick={() => {
								setStep(1);
								setSelectedProduct('FLEX_PRO');
							}}
						>
							{activeProduct.code === PRODUCT_TYPES.FLEX_PRO
								? 'Atual'
								: 'Upgrade'}
						</Button>
					</Box>
				</Box>
			)}

			{step === 1 && (
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						gap: 1,
					}}
				>
					<Typography variant="subtitle1">
						Para atualizar seu plano para a{' '}
						<strong>
							{products.find(
								product =>
									product.productCode === selectedProduct
							)?.name ?? selectedProduct}
						</strong>
						, informe sua assinatura eletrônica:
					</Typography>

					<OutlinedInput
						fullWidth
						size="small"
						type="password"
						placeholder="Assinatura"
						value={signature}
						onChange={e => setSignature(e.target.value)}
					/>

					<Box
						sx={{
							display: 'flex',
							flex: 1,
							justifyContent: 'flex-end',
							gap: 1,
						}}
					>
						<Button onClick={() => setStep(0)}>Cancelar</Button>
						<Button onClick={handleConfirm}>Confirmar</Button>
					</Box>
				</Box>
			)}

			{step === 2 && (
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						gap: 1,
						maxWidth: 400,
					}}
				>
					<Typography variant="subtitle1">
						Seu produto foi atualizado com sucesso!
					</Typography>

					<Typography variant="subtitle1">
						Ao clicar no botão abaixo, você será redirecionado para
						a tela de login para poder entrar novamente com suas
						configurações atualizadas.
					</Typography>

					<Box
						sx={{
							display: 'flex',
							flex: 1,
							justifyContent: 'flex-end',
						}}
					>
						<Button
							variant="contained"
							onClick={() => dispatch(doLogout())}
						>
							Ok
						</Button>
					</Box>
				</Box>
			)}
		</ModalTemplate>
	);
};
