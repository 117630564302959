import { Box, Button, styled } from '@mui/material';

export const RoundButton = styled(Button)(({ theme, toggled }) => ({
	borderRadius: '50%',
	minWidth: '30px',
	minHeight: '30px',
	width: '30px',
	height: '30px',
	padding: 0,
	backgroundColor: toggled
		? theme.palette.primary.main
		: theme.palette.primary.dark,
	color: 'white',
}));

export const StyledBox = styled(Box)(({ isHovered }) => ({
	border: isHovered ? '2px solid gray' : '2px solid transparent',
	transition: 'border 0.3s ease',
	borderRadius: '4px',
	paddingLeft: '4px',
	paddingRight: '4px',
}));
