import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
	Grid,
	Tooltip,
	Checkbox,
	Typography,
	IconButton,
	FormControlLabel,
	colors,
	Box,
} from '@mui/material';
import { Book, Info } from '@mui/icons-material';

import { NumericInput } from 'components/Bullet/NumericInput';
import { DepletionMarketPrice } from './DepletionMarketPrice';
import { LegSideButton } from 'components/Bullet/LegSideButton';
import ComboBoxInput from 'components/MaterialComponents/ComboBoxInput';
import { DescriptionTooltip, LightHTMLTooltip } from 'components/HelperTooltip';

import {
	additionalUpdate,
	updateCustomParameters,
	updateStrategyLeg,
} from 'store/modules/bottomBullet/actions';
import { EXM } from 'utils/bulletContext';
import paperValidator from 'utils/paperValidation';
import { symbolOpenBook } from 'utils/symbolOpenBook';
import addWidgetFromButton from 'utils/addWidgetFromButton';
import { onSuggestionsFetchRequested } from 'components/MaterialComponents/MaterialBullet/bulletFunctions';
import { getEntrancePriceInitialValue } from 'helpers/gradient/customParameters';

export const DepletionEntryParams = ({ setPaperInfo }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const bullet = useSelector(state => state.bottomBullet.bullet);
	const widgetIsLoading = useSelector(state => state.configs.widgetIsLoading);
	const bidValue = useSelector(
		state => state.bottomBullet.bullet.StrategyInfos[0].bidPx
	);
	const askValue = useSelector(
		state => state.bottomBullet.bullet.StrategyInfos[0].askPx
	);

	const paperFieldRef = useRef(null);

	const { suggestions, content, StrategyInfos, isMarketPrice } = bullet;

	const {
		EntrancePrice,
		NumberOfEntrances,
		OrderQuantity,
		IncrementPriceStepEntrance,
		IncrementPriceStopGain,
	} = content.CustomParameters;

	const { minOrderQty, roundLot, minPriceIncrement, tickSizeDenominator } =
		StrategyInfos[0];

	const isEdition = content.ClOrdID;
	const bulletLeg = content.StrategyLegs[0];

	const isBulletDisabled = paperValidator(StrategyInfos[0], bullet);

	useEffect(() => {
		if (isMarketPrice) {
			const marketPrice = getCurrentMarketPrice();

			dispatch(
				updateCustomParameters({
					EntrancePrice: marketPrice,
				})
			);
		}
	}, [isMarketPrice, bidValue, askValue]);

	useEffect(() => {
		const legSymbol = bulletLeg.LegRefSymbol ?? bulletLeg.LegSymbol;

		if (
			bullet.context === EXM &&
			(legSymbol?.startsWith('WDO') || legSymbol?.startsWith('WIN'))
		) {
			paperFieldRef.current?.focus();
			paperFieldRef.current?.blur();
			dispatch(additionalUpdate('refreshNeeded', false));
		}
	}, [bullet.refreshNeeded]);

	function getCurrentMarketPrice() {
		return bulletLeg.LegSide === '1' ? askValue : bidValue;
	}

	function changeLegSide() {
		const newLegSide = bulletLeg.LegSide === '1' ? '2' : '1';

		dispatch(updateStrategyLeg({ LegSide: newLegSide }, 0));

		const { asset, prevClosePx } = StrategyInfos[0];

		let entrancePrice = {};

		if (isMarketPrice) {
			const marketPrice = getCurrentMarketPrice();

			entrancePrice = { EntrancePrice: marketPrice };
		} else {
			const calculatedEntrancePrice = getEntrancePriceInitialValue(
				newLegSide,
				asset,
				prevClosePx,
				minPriceIncrement
			);

			entrancePrice = { EntrancePrice: calculatedEntrancePrice };
		}

		dispatch(
			updateCustomParameters({
				StopByPrice1: null,
				StopByPrice2: null,
				SelectStopByPrice1: 0,
				SelectStopByPrice2: 0,
				...entrancePrice,
			})
		);
	}

	function getPaperOptions() {
		if (suggestions?.source) {
			return {
				value: suggestions.source.id,
				title: suggestions.source.symbol,
			};
		}

		return suggestions;
	}

	const paperOptions = getPaperOptions();

	function setEntrancePrice(type) {
		dispatch(
			updateCustomParameters({
				EntrancePrice: StrategyInfos[0][type],
			})
		);
	}

	function handleChangeNumericCustomParameter(fieldName, value) {
		dispatch(updateCustomParameters({ [fieldName]: value }));
	}

	function handleToggleIsMarket() {
		if (isMarketPrice) {
			dispatch(updateCustomParameters({ EntrancePrice: null }));
		}

		dispatch(additionalUpdate('isMarketPrice', !isMarketPrice));
	}

	return (
		<>
			<Grid
				item
				container
				sx={{
					bgcolor: colors.grey[900],
					borderRadius: 1,
					p: 1,
				}}
			>
				<Box
					sx={{
						display: 'flex',
						gap: 1,
					}}
				>
					<Typography variant="subtitle1">
						Parâmetros de entrada
					</Typography>

					<LightHTMLTooltip
						element={<Info fontSize="small" sx={{ mt: 0.25 }} />}
					>
						<DescriptionTooltip
							label="Início de range"
							description={t('bullets.gradient.entrance_price')}
						/>
					</LightHTMLTooltip>
				</Box>

				<Grid item container spacing={1}>
					<Grid
						item
						xs
						sx={{
							display: 'flex',
							gap: 0.125,
							alignItems: 'center',
						}}
					>
						<Tooltip title="Abrir book">
							<IconButton
								size="small"
								onClick={() =>
									addWidgetFromButton(bulletLeg.LegSymbol)
								}
								disabled={
									symbolOpenBook(StrategyInfos, bullet) ||
									widgetIsLoading
								}
							>
								<Book />
							</IconButton>
						</Tooltip>

						<ComboBoxInput
							label="Ativo"
							name="paperField"
							isPaper
							comboOptions={paperOptions}
							inputRef={paperFieldRef}
							onChange={e =>
								onSuggestionsFetchRequested(
									e.target.value,
									false,
									false,
									0
								)
							}
							value={String(
								bulletLeg.LegRefSymbol ?? bulletLeg.LegSymbol
							).toUpperCase()}
							disabled={isEdition}
							setPaperInfo={setPaperInfo}
						/>
					</Grid>

					<Grid
						item
						xs
						sx={{ display: 'flex', alignItems: 'center' }}
					>
						<LegSideButton
							sx={{ height: 25 }}
							legSide={bulletLeg.LegSide}
							onClick={changeLegSide}
							disabled={paperValidator(
								StrategyInfos[0],
								bullet,
								'singleSideButtom'
							)}
						/>
					</Grid>

					<Grid
						item
						xs={2}
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<FormControlLabel
							control={<Checkbox />}
							label="A mercado"
							checked={isMarketPrice}
							disabled={isBulletDisabled}
							onChange={handleToggleIsMarket}
						/>
					</Grid>

					{!isMarketPrice && (
						<>
							<Grid item xs={3} sx={{ mt: -1 }}>
								<NumericInput
									disabled={isBulletDisabled || isEdition}
									label="Início de range"
									stepSize={minPriceIncrement}
									minValue={0}
									roundOnBlur
									value={EntrancePrice ?? ''}
									allowNegative
									fixedDecimalScale
									decimalScale={tickSizeDenominator}
									onChangeCallback={value =>
										handleChangeNumericCustomParameter(
											'EntrancePrice',
											value
										)
									}
								/>
							</Grid>
						</>
					)}

					<Grid item xs={2}>
						<DepletionMarketPrice
							setEntrancePrice={setEntrancePrice}
						/>
					</Grid>
				</Grid>
			</Grid>

			<Grid
				item
				container
				sx={{
					bgcolor: colors.grey[900],
					borderRadius: 1,
					p: 1,
					mt: 1,
				}}
			>
				<Box
					sx={{
						display: 'flex',
						gap: 1,
					}}
				>
					<Typography variant="subtitle1">
						Configuração de execução
					</Typography>

					<LightHTMLTooltip
						element={<Info fontSize="small" sx={{ mt: 0.25 }} />}
					>
						<DescriptionTooltip
							label="Qtd. Níveis de entrada"
							description={t(
								'bullets.gradient.quantity_entrance'
							)}
						/>

						<DescriptionTooltip
							label="Qtd. por nível"
							description={t('bullets.gradient.quantity_level')}
						/>

						<DescriptionTooltip
							label="Espaçamento entradas"
							description={t('bullets.gradient.step_entrance')}
						/>

						<DescriptionTooltip
							label="Objetivo de Gain/Trade Mercado"
							description={t('bullets.gradient.increment_price')}
						/>
					</LightHTMLTooltip>
				</Box>

				<Grid item container spacing={1}>
					<Grid item xs={3}>
						<NumericInput
							disabled={isBulletDisabled}
							label="Qtd. níveis de entrada"
							value={NumberOfEntrances}
							stepSize={1}
							minValue={1}
							roundOnBlur
							onChangeCallback={value =>
								handleChangeNumericCustomParameter(
									'NumberOfEntrances',
									value
								)
							}
						/>
					</Grid>

					<Grid item xs={3}>
						<NumericInput
							disabled={isBulletDisabled}
							label="Qtd. por nível"
							value={OrderQuantity}
							stepSize={roundLot || minOrderQty}
							minValue={minOrderQty ?? 0}
							maxValue={minOrderQty * 50 ?? null}
							roundOnBlur
							onChangeCallback={value =>
								handleChangeNumericCustomParameter(
									'OrderQuantity',
									value
								)
							}
						/>
					</Grid>

					<Grid item xs={3}>
						<NumericInput
							disabled={isBulletDisabled || isEdition}
							label="Espaçamento entradas"
							value={IncrementPriceStepEntrance ?? 0}
							stepSize={minPriceIncrement}
							minValue={minPriceIncrement}
							roundOnBlur
							onChangeCallback={value =>
								handleChangeNumericCustomParameter(
									'IncrementPriceStepEntrance',
									value
								)
							}
						/>
					</Grid>

					<Grid item xs={3}>
						<NumericInput
							disabled={isBulletDisabled}
							label="Obj. Gain/Trade mercado"
							value={IncrementPriceStopGain}
							stepSize={minPriceIncrement}
							minValue={minPriceIncrement}
							roundOnBlur
							onChangeCallback={value =>
								handleChangeNumericCustomParameter(
									'IncrementPriceStopGain',
									value
								)
							}
						/>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};
