import React from 'react';
import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
	Box,
	Button,
	ButtonGroup,
	colors,
	FormControl,
	FormControlLabel,
	FormLabel,
	Grid,
	Radio,
	RadioGroup,
	Typography,
} from '@mui/material';
import { Info } from '@mui/icons-material';

import paperValidator from 'utils/paperValidation';
import { DescriptionTooltip, LightHTMLTooltip } from 'components/HelperTooltip';
import { updateCustomParameters } from 'store/modules/bottomBullet/actions';
import { NumericInput } from 'components/Bullet/NumericInput';

export const GapExitParams = () => {
	const dispatch = useDispatch();

	const bullet = useSelector(state => state.bottomBullet.bullet);

	const { content, StrategyInfos } = bullet;

	const {
		ExitUnit,
		ExitOnEndDayAuction,
		ExitOnEndTime,
		ExitPxSlippagePts,
		ExitPxRelGain,
		ExitPxRelLoss,
	} = content.CustomParameters;

	const { minPriceIncrement, tickSizeDenominator } = StrategyInfos[0];

	const notSelectedButtonStyle = {
		color: colors.grey[500],
		borderColor: colors.grey[600],
	};

	const isBulletDisabled = paperValidator(StrategyInfos[0], bullet);
	const isBulletDisabledBySingleSideButton = paperValidator(
		StrategyInfos[0],
		bullet,
		'singleSideButtom'
	);

	const exitOnSelection = ExitOnEndTime === 'Y' ? 'endTime' : 'endDayAuction';

	function handleToggleExitOnSelection() {
		dispatch(
			updateCustomParameters({
				ExitOnEndTime: ExitOnEndTime === 'Y' ? 'N' : 'Y',
				ExitOnEndDayAuction: ExitOnEndDayAuction === 'Y' ? 'N' : 'Y',
			})
		);
	}

	function handleToggleExitUnit() {
		dispatch(
			updateCustomParameters({
				ExitUnit: ExitUnit === 'I' ? 'P' : 'I',
				ExitPxRelGain: '',
				ExitPxRelLoss: '',
			})
		);
	}

	function handleUpdateCustomParameters(field, value) {
		dispatch(
			updateCustomParameters({
				[field]: value,
			})
		);
	}

	return (
		<Grid
			item
			container
			direction="column"
			sx={{
				bgcolor: colors.grey[900],
				borderRadius: 1,
				p: 1,
			}}
		>
			<Box
				sx={{
					display: 'flex',
					gap: 1,
				}}
			>
				<Typography variant="subtitle1">Parâmetros de Saída</Typography>

				<LightHTMLTooltip
					element={<Info fontSize="small" sx={{ mt: 0.25 }} />}
				>
					<DescriptionTooltip
						label="Início de range"
						description={t('bullets.gradient.entrance_price')}
					/>
				</LightHTMLTooltip>
			</Box>

			<Grid item container direction="column">
				<Grid item container spacing={1}>
					<Grid
						item
						xs={6}
						sx={{
							display: 'flex',
							alignItems: 'flex-end',
						}}
					>
						<ButtonGroup
							fullWidth
							size="small"
							disabled={isBulletDisabledBySingleSideButton}
							sx={{
								mb: 0.25,
								height: 25,
								cursor: isBulletDisabledBySingleSideButton
									? 'not-allowed'
									: 'auto',
							}}
							onClick={handleToggleExitUnit}
						>
							<Button
								color={ExitUnit === 'I' ? 'primary' : 'inherit'}
								variant={
									ExitUnit === 'I' ? 'contained' : 'outlined'
								}
								sx={
									ExitUnit === 'P'
										? notSelectedButtonStyle
										: {}
								}
							>
								Pontos
							</Button>

							<Button
								color={ExitUnit === 'P' ? 'primary' : 'inherit'}
								variant={
									ExitUnit === 'P' ? 'contained' : 'outlined'
								}
								sx={
									ExitUnit === 'I'
										? notSelectedButtonStyle
										: {}
								}
							>
								%
							</Button>
						</ButtonGroup>
					</Grid>
					<Grid item xs={6}>
						<NumericInput
							id="ExitPxRelGain"
							label={`Gain (${
								ExitUnit === 'I' ? 'pontos' : '%'
							})`}
							suffix={ExitUnit === 'I' ? '' : '%'}
							value={ExitPxRelGain ?? ''}
							onChangeCallback={value => {
								handleUpdateCustomParameters(
									'ExitPxRelGain',
									value
								);
							}}
							roundOnBlur
							decimalScale={
								ExitUnit === 'I' ? tickSizeDenominator : 2
							}
							stepSize={
								ExitUnit === 'P' ? 0.01 : minPriceIncrement
							}
							minValue={minPriceIncrement}
							disabled={isBulletDisabled}
							fixedDecimalScale
						/>
					</Grid>
				</Grid>

				<Grid item container spacing={1}>
					<Grid item xs={6}>
						<NumericInput
							value={ExitPxRelLoss ?? ''}
							onChangeCallback={value =>
								handleUpdateCustomParameters(
									'ExitPxRelLoss',
									value
								)
							}
							roundOnBlur
							decimalScale={
								ExitUnit === 'I' ? tickSizeDenominator : 2
							}
							stepSize={
								ExitUnit === 'P' ? 0.01 : minPriceIncrement
							}
							minValue={minPriceIncrement}
							disabled={isBulletDisabled}
							fixedDecimalScale
							label={`Loss (${
								ExitUnit === 'I' ? 'pontos' : '%'
							})`}
							suffix={ExitUnit === 'I' ? '' : '%'}
						/>
					</Grid>

					<Grid item xs={6}>
						<NumericInput
							value={ExitPxSlippagePts ?? ''}
							onChangeCallback={value =>
								handleUpdateCustomParameters(
									'ExitPxSlippagePts',
									value
								)
							}
							roundOnBlur
							decimalScale={tickSizeDenominator}
							stepSize={minPriceIncrement}
							minValue={minPriceIncrement}
							disabled={isBulletDisabled}
							fixedDecimalScale
							label="Slippage (pontos)"
						/>
					</Grid>
				</Grid>

				<Grid item container spacing={1}>
					<Grid item xs sx={{ mt: 1 }}>
						<FormControl disabled={isBulletDisabled}>
							<FormLabel>Termina em:</FormLabel>
							<RadioGroup
								row
								value={exitOnSelection}
								sx={{ flexWrap: 'nowrap' }}
								onChange={handleToggleExitOnSelection}
							>
								<FormControlLabel
									value="ExitOnEndTime"
									control={<Radio />}
									label="Hora fim"
									checked={exitOnSelection === 'endTime'}
								/>

								<FormControlLabel
									value="ExitOnEndDayAuction"
									control={<Radio />}
									label="Leilão de fechamento"
									checked={
										exitOnSelection === 'endDayAuction'
									}
								/>
							</RadioGroup>
						</FormControl>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};
