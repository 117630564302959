import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Box, Divider, Stack, Typography } from '@mui/material';
import { setIsGlobalSettingsOpen } from 'store/modules/panelConfig/actions';

import { StyledBox } from './styles';

function valueLabelFormat(value, digits) {
	if (!value) {
		value = 0.0;
	}

	return `R$ ${value.toLocaleString('pt-BR', {
		maximumFractionDigits: digits,
		minimumFractionDigits: digits,
	})}`;
}

export const IdxStops = ({ finValue }) => {
	const dispatch = useDispatch();

	const [isHoveredLoss, setIsHoveredLoss] = useState(false);
	const [isHoveredGain, setIsHoveredGain] = useState(false);

	const handleMouseOverLoss = () => setIsHoveredLoss(true);
	const handleMouseOverGain = () => setIsHoveredGain(true);
	const handleMouseOutLoss = () => setIsHoveredLoss(false);
	const handleMouseOutGain = () => setIsHoveredGain(false);

	const gradientStopReduxValues = useSelector(
		state => state.idxLiveCharts.gradientStop
	);

	const [finLimitLossValue, setFinLimitLossValue] = useState(
		valueLabelFormat(gradientStopReduxValues.finLimitLoss, 0)
	);
	const [finLimitGainValue, setFinLimitGainValue] = useState(
		valueLabelFormat(gradientStopReduxValues.finLimitGain, 0)
	);

	const [financialValue, setFinancialValue] = useState(
		valueLabelFormat(finValue, 2)
	);

	const financialRef = useRef(finValue);

	useEffect(() => {
		setFinLimitLossValue(
			valueLabelFormat(gradientStopReduxValues.finLimitLoss, 0)
		);
		setFinLimitGainValue(
			valueLabelFormat(gradientStopReduxValues.finLimitGain, 0)
		);

		setFinancialValue(valueLabelFormat(finValue, 2));

		financialRef.current = finValue;
	}, [finValue, gradientStopReduxValues]);

	return (
		<Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
			<Stack
				direction="row"
				divider={<Divider orientation="vertical" flexItem />}
				spacing={2}
			>
				<StyledBox
					isHovered={isHoveredLoss}
					onMouseOver={handleMouseOverLoss}
					onMouseOut={handleMouseOutLoss}
					onClick={() =>
						dispatch(setIsGlobalSettingsOpen(true, 'gradient'))
					}
				>
					<Typography sx={{ fontSize: '13px' }}>Stop Loss</Typography>
					<Typography
						color={
							gradientStopReduxValues.finLimitLoss < 0
								? 'red'
								: 'green'
						}
						sx={{ fontSize: '15px' }}
					>
						{finLimitLossValue}
					</Typography>
				</StyledBox>
				<box>
					<Typography sx={{ fontSize: '15px' }} align="center">
						<strong>Financeiro Acumulado</strong>
					</Typography>
					<Typography
						align="center"
						color={financialRef.current < 0 ? 'red' : 'green'}
						sx={{ fontSize: '18px' }}
					>
						<strong>{financialValue}</strong>
					</Typography>
				</box>
				<StyledBox
					isHovered={isHoveredGain}
					onMouseOver={handleMouseOverGain}
					onMouseOut={handleMouseOutGain}
					onClick={() =>
						dispatch(setIsGlobalSettingsOpen(true, 'gradient'))
					}
				>
					<Typography sx={{ fontSize: '13px' }}>Stop Gain</Typography>
					<Typography
						color={
							gradientStopReduxValues.finLimitGain < 0
								? 'red'
								: 'green'
						}
						sx={{ fontSize: '15px' }}
					>
						{finLimitGainValue}
					</Typography>
				</StyledBox>
			</Stack>
		</Box>
	);
};
