import moment from 'moment';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import { getOptUnderlyingStockSecurityTypes } from '@investflex/iflexquantjs';

import { getStrategyTemplate } from './templates';

import { positionModel, strategyModel } from 'pages/Simulator/utils';

export const strategiesList = [
	{ title: 'SO', uid: 'sorder', tooltip: 'Single Order' },
	{ title: 'FO', uid: 'fxorder', tooltip: 'Flex Order' },
	{ title: 'BO', uid: 'boffer', tooltip: 'Best Offer' },
	{ title: 'SN', uid: 'sniper', tooltip: 'Sniper' },
	{ title: 'MI', uid: 'mit', tooltip: 'Mit' },
	{ title: 'SP', uid: 'spread', tooltip: 'Spread' },
	{ title: 'FI', uid: 'financ', tooltip: 'Financiamento' },
	{ title: 'LS', uid: 'lgshort', tooltip: 'Long & Short' },
	{ title: 'GL', uid: 'grdlin', tooltip: 'Gradiente Linear' },
	{ title: 'GD', uid: 'grddin', tooltip: 'Gradiente Dinâmico' },
	{ title: 'IB', uid: 'iceberg', tooltip: 'Iceberg' },
	{ title: 'TW', uid: 'twap', tooltip: 'Twap' },
	{ title: 'PV', uid: 'pov', tooltip: 'Pov' },
	{ title: 'MM', uid: 'marketmaker', tooltip: 'Market Maker' },
	{ title: 'PB', uid: 'portbalance', tooltip: 'Balanceamento de carteira' },
];

export const getSorderSubUid = content => {
	if (content.CustomParameters.CustomOrdType === 2) {
		return 3;
	}
	return content.StrategyLegs[0].LegOrdType === '2'
		? 1 /* SLIMIT */
		: 2 /* SMARKET */;
};

export const getFxorderSubUid = content => {
	const type = parseInt(content.CustomParameters.CustomOrdType);
	switch (type) {
		case 1:
			return 2;
		case 2:
			return 3;
		case 3:
			return 4;
		case 4:
			return 1;
		case 5:
			return 5;
		default:
			return 1;
	}
};

export const getSubExchangeFromSecurityType = securityType => {
	return ['FUT', 'SPOT', 'DTERM', 'SOPT', 'FOPT'].includes(securityType)
		? 'XBMF'
		: 'XBSP';
};

function getGradientSubUid(name) {
	if (String(name).startsWith('GDE-')) {
		return 3;
	}

	if (String(name).startsWith('GDM-')) {
		return 3;
	}

	return 1;
}

export const getSubUid = content => {
	switch (content.StrategyCode) {
		case 'sorder':
			return getSorderSubUid(content);
		case 'fxorder':
			return getFxorderSubUid(content);
		case 'boffer':
			return 7;
		case 'sniper':
			return 8;
		case 'mit':
			return 9;
		case 'spread':
		case 'spread2p':
			return 1;
		case 'spread3p':
			return 2;
		case 'spread4p':
			return 3;
		case 'spread5p':
			return 4;
		case 'spread6p':
			return 5;
		case 'financ':
			return 6;
		case 'iceberg':
			return 1;
		case 'twap':
			return 2;
		case 'pov':
			return 3;
		case 'cvvol':
			return 1;
		case 'skew':
			return 2;
		case 'marketmaker':
			return 1;
		case 'portbalance':
			return 1;
		case 'grddin':
			return getGradientSubUid(content.Name);
		default:
			return 1;
	}
};

// "book": "PETRK15#0.0|0|0.0|0#0.0|0|0.0|0#0.0|0|0.0|0#0.0|0|0.0|0#0.0|0|0.0|0#0.0|0|0.0|0#0.0|0|0.0|0#0.0|0|0.0|0#0.0|0|0.0|0#0.0|0|0.0|0",
export const parseBook = book => {
	const tempBook = {};

	tempBook.bidPrices = [];
	tempBook.bidQuantities = [];
	tempBook.askPrices = [];
	tempBook.askQuantities = [];

	const tokens = book.split('#');

	tokens.forEach((token, index) => {
		if (index === 0) {
			tempBook.symbol = token;
		} else {
			const bookLevels = token.split('|');
			tempBook.bidPrices.push(parseFloat(bookLevels[0]));
			tempBook.bidQuantities.push(parseInt(bookLevels[1], 10));
			tempBook.askPrices.push(parseFloat(bookLevels[2]));
			tempBook.askQuantities.push(parseInt(bookLevels[3], 10));
		}
	});

	return tempBook;
};

export const getFinatialValue = (lastPx, qtd, asset) => {
	const priceToCalc =
		asset && ['WIN', 'WDO'].includes(asset) ? lastPx / 5 : lastPx;

	return parseFloat((priceToCalc * qtd).toFixed(2));
};

export const getDecimalPlacesByTrigger = trigger => {
	switch (parseInt(trigger, 10)) {
		case 1:
			return 4; // Spread
		case 2:
			return 5; // Ratio C/V
		case 3:
			return 5; // Ratio V/C
		case 4:
			return 2; // Dif. Spread C-V
		case 5:
			return 2; // Dif. Spread V-C
		case 11:
			return 2; // Dif. Financeiro
		case 16:
			return 3; // Taxa
		default:
			return 2;
	}
};

export const getStepByTrigger = trigger => {
	switch (parseInt(trigger, 10)) {
		case 1:
			return 0.01; // Spread
		case 2:
			return 0.00001; // Ratio C/V
		case 3:
			return 0.00001; // Ratio V/C
		case 4:
			return 0.01; // Dif. Spread C-V
		case 5:
			return 0.01; // Dif. Spread V-C
		case 11:
			return 1; // Dif. Financeiro
		case 16:
			return 0.001; // Taxa
		default:
			return 0.01;
	}
};

export const hasExpiratedSymbols = strategyInfos => {
	return strategyInfos.some(info => {
		const { securityType, maturityDate } = info;

		if (
			['OPT', 'SOPT', 'FOPT', 'INDEXOPT', 'FUT'].includes(securityType) &&
			maturityDate &&
			maturityDate.length === 8
		) {
			const tempDate = new Date(
				+maturityDate.slice(0, 4),
				maturityDate.slice(4, 6) - 1,
				+maturityDate.slice(6)
			);

			const today = moment(new Date()).format('YYYY-MM-DD');
			const newMaturityDate = moment(tempDate).format('YYYY-MM-DD');

			return moment(today).isAfter(newMaturityDate);
		}

		return false;
	});
};

function getFormattedGradientInitialName(subUid, isMarketPrice) {
	if (subUid === 3) {
		if (isMarketPrice) {
			return 'GDM';
		}

		return 'GDE';
	}

	return 'GD';
}

export const getStrategyName = (bullet, positions) => {
	let firstName;

	const getFxorderSigle = bullet => {
		switch (bullet.subUid) {
			case 1:
				return 'LI';
			case 2:
				return 'MK';
			case 3:
				return 'ST';
			case 4:
				return 'SA';
			case 5:
				return 'LA';
			case 6:
				return 'LF';
			default:
				return 'LI';
		}
	};

	switch (bullet.content.StrategyCode) {
		case 'sorder':
			firstName = bullet.subUid === 1 ? 'S-LI' : 'S-MK';
			break;
		case 'fxorder':
			firstName = `S-${getFxorderSigle(bullet)}`;
			break;
		case 'boffer':
			firstName = 'S-BO';
			break;
		case 'sniper':
			firstName = 'S-SN';
			break;
		case 'mit':
			firstName = 'S-MT';
			break;
		case 'spread':
		case 'spread2p':
		case 'spread3p':
		case 'spread4p':
		case 'spread5p':
		case 'spread6p':
			firstName = 'SP';
			break;
		case 'financ':
			firstName = 'SP-FN';
			break;
		case 'lgshort':
			firstName = 'LS';
			break;
		case 'grdlin':
		case 'grddin':
			firstName = getFormattedGradientInitialName(
				bullet.subUid,
				bullet.isMarketPrice
			);
			break;
		case 'iceberg':
			firstName = 'PA-IC';
			break;
		case 'twap':
			firstName = 'PA-TW';
			break;
		case 'pov':
			firstName = 'PA-PV';
			break;
		case 'cvvol':
			firstName = 'VL-VOL';
			break;
		case 'skew':
			firstName = 'VL-SKEW';
			break;
		case 'marketmaker':
			firstName = 'MM';
			break;
		case 'portbalance':
			firstName = `PB-${bullet.account}`;
			break;
		case 'gap':
			firstName = 'GAP';
			break;
		default:
			firstName = 'S-LI';
			break;
	}

	let secondName;

	// Pegando os symbols
	let symbols = '';
	bullet.content.StrategyLegs.forEach(element => {
		symbols += `-${element.LegSymbol}`;
	});

	let side = '';
	if (bullet.content.StrategyLegs && bullet.content.StrategyLegs.length > 0) {
		//pegando o side da primeira perna
		side = bullet.content.StrategyLegs[0].LegSide === '1' ? 'BUY' : 'SELL';
	}

	//caso tenha mais de uma perna exibimos apenas os symbol, caso contrario o side tb e exibido
	secondName =
		bullet.content.StrategyLegs.length > 1 ? symbols : `-${side}${symbols}`;

	let finalName = `${firstName}${secondName}`;

	//verificando duplicidade
	const equalNames = positions.filter(el => el?.Name === finalName);
	if (equalNames.length > 0 && !window.Cypress) {
		const date = new Date();
		finalName += `-${date.getHours()}.${date.getMinutes()}.${date.getSeconds()}`;
		const equalSecond = positions.filter(el => el?.Name === finalName);
		if (equalSecond.length > 0) {
			finalName += `.${date.getMilliseconds()}`;
		}
	}
	return finalName;
};

export const getRevertedStrategyName = (bullet, positions) => {
	let finalName = '';
	let bulletName = getStrategyName(bullet, positions);

	let quantityRevPositions = positions.reduce((quantityRev, item) => {
		if (item.Name.includes('REV')) {
			quantityRev++;
		}

		return quantityRev;
	}, 0);

	quantityRevPositions =
		quantityRevPositions > 0 ? quantityRevPositions + 1 : 1;

	finalName = `REV-${quantityRevPositions}-${bulletName}`;

	return finalName;
};

export const handleStrategyCode = (typeId, value, category) => {
	if (typeId === 'subUid') {
		if (category === 1) {
			switch (value) {
				case 1:
				case 2:
					return 'sorder';
				case 3:
				case 4:
					return 'fxorder';
				case 5:
				case 6:
					return 'sorder';
				case 7:
					return 'boffer';
				case 8:
					return 'sniper';
				case 9:
					return 'mit';
				default:
					return 'sorder';
			}
		}

		if (category === 2) {
			switch (value) {
				case 1:
					return 'spread';
				case 2:
					return 'spread3p';
				case 3:
					return 'spread4p';
				case 4:
					return 'spread5p';
				case 5:
					return 'spread6p';
				case 6:
					return 'financ';
				default:
					return 'spread2p';
			}
		}

		if (category === 3) {
			return 'lgshort';
		}

		if (category === 4) {
			switch (value) {
				case 1:
					return 'grdlin';
				case 2:
				case 3:
					return 'grddin';
				default:
					return 'grdlin';
			}
		}

		if (category === 5) {
			switch (value) {
				case 1:
					return 'iceberg';
				case 2:
					return 'twap';
				case 3:
					return 'pov';
				default:
					return 'iceberg';
			}
		}

		if (category === 6) {
			switch (value) {
				case 1:
					return 'cvvol';
				case 2:
					return 'skew';
				default:
					return 'cvvol';
			}
		}

		if (category === 7) {
			return 'marketmaker';
		}

		if (category === 8) {
			return 'portbalance';
		}

		if (category === 9) {
			return 'gap';
		}
	}
};

export const changeStrategyName = value => {
	let newName;
	switch (value) {
		case 1:
			newName = 'single_limite';
			break;
		case 2:
			newName = 'single_mercado';
			break;
		case 3:
			newName = 'single_start';
			break;
		case 4:
			newName = 'single_saida';
			break;
		case 5:
			newName = 'single_leilao_de_abertura';
			break;
		case 6:
			newName = 'single_leilao_de_fechamento';
			break;
		case 7:
			newName = 'single_best_offer';
			break;
		case 8:
			newName = 'single_sniper';
			break;
		case 9:
			newName = 'single_mit';
			break;
		case 'spread':
			newName = 'spread2p';
			break;
		case 'spread2p':
		case 'spread3p':
		case 'spread4p':
		case 'spread5p':
		case 'spread6p':
			newName = value;
			break;
		case 'financ':
			newName = 'financiamento';
			break;
		case 'lgshort':
			newName = 'longshort';
			break;
		case 'marketmaker':
			newName = 'marketmaker';
			break;
		case 'portbalance':
			newName = 'portbalance';
			break;
		default:
			newName = 'single_limit';
			break;
	}

	if (newName) {
		return `${newName}${new Date()
			.toLocaleString()
			.replace(/[^0-9a-z]/gi, '')}`;
	}

	return value;
};

export const getDefaultLegs = numLegs => {
	const arrayLegs = [];

	for (let i = 0; i < parseInt(numLegs); i++) {
		arrayLegs.push({
			ILegAllocAccount: '',
			LegQuantity: 0,
			LegSecurityExchange: 'XBSP',
			LegSide: i % 2 === 0 ? '1' : '2',
			LegSymbol: '',
			LegResting: 'N',
			LegMaxClipSize: 0,
			LegMinClipSize: 0,
		});
	}

	return arrayLegs;
};

export const getStrategyCodeUsingTemplatesIds = strategyCode => {
	switch (strategyCode) {
		case 'sorder':
		case 'fxorder':
		case 'boffer':
		case 'sniper':
		case 'mit':
		case 'spread':
		case 'financ':
		case 'lgshort':
		case 'grdlin':
		case 'grddin':
		case 'iceberg':
		case 'twap':
		case 'pov':
		case 'cvvol':
		case 'skew':
		case 'marketmaker':
		case 'portbalance':
		case 'gap':
			return strategyCode;
		case 'spread2p':
		case 'spread3p':
		case 'spread4p':
		case 'spread5p':
		case 'spread6p':
			return 'spread';
		default:
			return 'sorder';
	}
};

export const handleOrdType = value => {
	if (value === 1) {
		return 4;
	}
	if (value === 2) {
		return 1;
	}
	if (value === 3) {
		return 2;
	}
	if (value === 4) {
		return 3;
	}
	if (value === 5 || value === 6) {
		return 5;
	}
	return '';
};

export const getNumOfLegs = strategyCode => {
	switch (strategyCode) {
		case 'sorder':
		case 'fxorder':
		case 'bforder':
		case 'sniper':
		case 'mit':
		case 'grdlin':
		case 'grddin':
			return 1;
		case 'spread':
		case 'spread2p':
		case 'lgshort':
			return 2;
		case 'spread3p':
			return 3;
		case 'spread4p':
		case 'financ':
			return 4;
		case 'spread5p':
			return 5;
		case 'spread6p':
			return 6;
		case 'cvvol':
			return 2;
		case 'skew':
			return 2;
		case 'marketmaker':
			return 2;
		case 'portbalance':
			return 0;
		default:
			return 1;
	}
};

export const getStrategyStatus = statusId => {
	switch (statusId) {
		case '0':
			return 'Executando';
		case '1':
			return 'Parcialmente finalizada';
		case '2':
			return 'Finalizada';
		case '4':
			return 'Cancelada';
		case '5':
			return 'Editada';
		case '8':
			return 'Rejeitado';
		case '9':
			return 'Suspensa';
		case 'C':
			return 'Expirada';
		case 'Z':
			return 'Estado final anterior';
		default:
			break;
	}
};

export const getOrderStatus = statusId => {
	switch (statusId) {
		case '0':
			return 'Nova';
		case '2':
			return 'Agendada';
		case '3':
			return 'Executando';
		case '4':
			return 'Desbalanceada';
		case '5':
			return 'Finalizada';
		case '6':
			return 'Cancelando';
		case '7':
			return 'Cancelada';
		case '8':
			return 'Suspendendo';
		case '9':
			return 'Suspensa';
		case '10':
			return 'Iniciando';
		case '99':
			return 'Aguardando resposta';
		default:
			break;
	}
};

export const showProjectedCoust = bulletObject => {
	const tempStrategyInfos = bulletObject.StrategyInfos.filter(
		e => e.securityType === 'FUT' || e.securityType === 'FOPT'
	);
	if (bulletObject.content.ClOrdID) {
		return false;
	} else if (tempStrategyInfos.length === 0) {
		return true;
	} else {
		return false;
	}
};

export const formatedDateTime = dateEn => {
	if (!dateEn.includes(' ')) {
		return null;
	}

	const data = dateEn.split(' ');

	if (data.length <= 0) {
		return null;
	}

	const formattingDatePtBr = data[0].split('-').reverse().join('/');

	const dateTime = `${formattingDatePtBr} ${data[1]}`;

	return dateTime;
};

export const marginLabelBullet = widgetOpen => {
	const marginAdjust = { marginLeft: '-7px' };

	if (widgetOpen) {
		marginAdjust.marginLeft = '-12px';
	}

	return marginAdjust;
};

export const dashboardToolsMargin = (menuOpen, widgetOpen) => {
	const marginAdjust = { marginRight: '0px' };

	if (menuOpen && widgetOpen) {
		marginAdjust.marginRight = '390px';
	} else if (widgetOpen) {
		marginAdjust.marginRight = '250px';
	} else if (menuOpen) {
		marginAdjust.marginRight = '140px';
	}

	return marginAdjust;
};

export const getCategoryFromStrategyCode = strategyCode => {
	switch (strategyCode) {
		case 'sorder':
		case 'fxorder':
		case 'boffer':
		case 'sniper':
		case 'mit':
			return 1;
		case 'spread':
		case 'spread2p':
		case 'spread3p':
		case 'spread4p':
		case 'spread5p':
		case 'spread6p':
		case 'financ':
			return 2;
		case 'lgshort':
			return 3;
		case 'grdlin':
		case 'grddin':
			return 4;
		case 'iceberg':
		case 'twap':
		case 'pov':
			return 5;
		case 'cvvol':
		case 'skew':
			return 6;
		case 'marketmaker':
			return 7;
		case 'portbalance':
			return 8;
		case 'gap':
			return 9;
		default:
			return 1;
	}
};

const spreadLeg = {
	ILegAllocAccount: '',
	LegQuantity: 0,
	LegSecurityExchange: 'XBSP',
	LegSide: '1',
	LegSymbol: '',
	LegResting: 'N',
	LegMaxClipSize: 0,
};

export const bulletInitialState = {
	category: '1',
	category_type: '',
	code: '',
	closeOnSubmit: true,
	tooltip: 'Single',
	visible: true,
	uid: 'sorder',
	subUid: 1,
	id: 0.4180047046265263,
	StrategyInfos: [{}],
	legs: [spreadLeg],
	content: {
		signature: '',
		...getStrategyTemplate('sorder'),
	},
	signature: '',
};

export const getDefaultBmfAssetList = () => {
	const assetList = process.env.REACT_APP_BMF_ASSET_DEFAULT_LIST;
	if (assetList) {
		const assetListParsed = assetList.split(',');
		if (assetListParsed.length > 0) {
			return assetListParsed;
		}
	}

	return ['WIN', 'WDO', 'IND', 'DOL', 'CCM'];
};

export const internalParseInt = value => {
	return value && typeof value === 'string' ? parseInt(value, 10) : value;
};
export const internalParseFloat = value => {
	return value && typeof value === 'string' ? parseFloat(value) : value;
};
export const parseCustomStrategyFields = (field, value) => {
	switch (field) {
		case 'BookDepth':
		case 'CompensateExec':
		case 'ExecStrategy':
		case 'QuantityMode':
		case 'RetroalimentType':
		case 'ExecutionType':
		case 'TriggerType':
		case 'IgnoreOffersLT':
		case 'MaximumZeroTimeWindow':
		case 'DaysToExpiration':
		case 'ExecutionLimit':
			return internalParseInt(value);
		case 'InterestRate':
		case 'PriceLimit':
		case 'MaxFinancialVolume':
		case 'ToMarketTriggerValue':
		case 'OrderQuantity':
		case 'EntrancePrice':
		case 'IncrementPriceStepEntrance':
		case 'NumberOfEntrances':
		case 'IncrementPriceStopGain':
		case 'StopLossFinance':
		case 'StopByPrice1':
		case 'StopGainGlobalFinance':
		case 'PreservationStartTrigger':
		case 'PreservationPercentual':
		case 'RetroalimentParam1':
		case 'DeltaFixedValue':
			return internalParseFloat(value);
		default:
			return value;
	}
};

export const parseStrategyLegsFields = (field, value) => {
	switch (field) {
		case 'LegQuantity':
		case 'LegMaxClipSize':
		case 'LegMinClipSize':
		case 'LegFirstTimeOut':
		case 'LegSecondTimeOut':
			return internalParseInt(value);
		case 'LegPrice':
			return internalParseFloat(value);
		default:
			return value;
	}
};

export const getFirstLegFlexOrder = strategyLegs => {
	let newLeg = {};
	strategyLegs.forEach(leg => {
		if (leg.LegRefID) {
			const parts = leg.LegRefID.split(':');
			if (parts.length > 1 && parseInt(parts[1], 10) === 1) {
				newLeg = { ...leg };
			}
		}
	});
	strategyLegs = [];
	strategyLegs.push(newLeg);
	return strategyLegs;
};

export const getParsedCustomParameters = customParameters => {
	if (customParameters) {
		for (const [key, value] of Object.entries(customParameters)) {
			customParameters[key] = parseCustomStrategyFields(key, value);
		}
	}
	return customParameters;
};
export const getParsedStrategyLegFields = leg => {
	if (leg) {
		for (const [key, value] of Object.entries(leg)) {
			leg[key] = parseStrategyLegsFields(key, value);
		}
	}
	return leg;
};

export const orderStatus = [
	{
		value: 'all',
		label: 'Todos',
	},
	{
		value: 2,
		label: 'Agendada',
	},
	{
		value: 3,
		label: 'Executando',
	},
	{
		value: 4,
		label: 'Desbalanceada',
	},
	{
		value: 5,
		label: 'Finalizada',
	},
	{
		value: 6,
		label: 'Cancelando',
	},
	{
		value: 7,
		label: 'Cancelada',
	},
	{
		value: 8,
		label: 'Suspendendo',
	},
	{
		value: 9,
		label: 'Suspensa',
	},
	{
		value: 10,
		label: 'Iniciando',
	},
];

export const hasExecutedQuantity = row => {
	const executedQuantityExists = leg =>
		!leg.LegExecQty || (leg.LegExecQty && leg.LegExecQty <= 0);

	const legs = row?.StrategyLegs ?? row?.Legs;

	if (row?.ExecutedQtty) {
		if (legs && legs > 1 && !['1014'].includes(row?.TargetStrategy)) {
			return !legs.some(executedQuantityExists);
		}
		return [null, undefined].includes(row.ExecutedQtty)
			? false
			: parseInt(row.ExecutedQtty, 10) > 0;
	}
	return false;
};

export const checkStrategyRevertPermissions = row => {
	if (row?.StrategyCode) {
		return [
			'sorder',
			'spread',
			'spread2p',
			'spread3p',
			'spread4p',
			'spread5p',
			'spread6p',
			'cvvol',
			'twap',
			'mit',
			'sniper',
			'boffer',
			'fxorder',
			'lgshort',
			'gap',
		].includes(row?.StrategyCode);
	} else if (row?.AtdlID) {
		return [
			'1007',
			'2002',
			'1004',
			'1004_1',
			'1004_2',
			'1004_3',
			'1004_4',
			'1003_2',
			'1014',
			'1010',
			'1019',
			'1002',
			'1001',
			'1038',
		].includes(row?.AtdlID);
	} else {
		return false;
	}
};

export const showActionRevertByStrategyType = row => {
	if (checkStrategyRevertPermissions(row)) {
		const legs = row?.StrategyLegs ?? row?.Legs;

		switch (row?.StrategyCode) {
			case 'sorder':
				return legs && legs.length > 0
					? ['1', 'k', 'K', '2'].includes(legs[0].LegOrdType)
					: false;
			case 'fxorder':
				return (
					[2, '2'].includes(row?.CustomParameters?.CustomOrdType) &&
					[4, '4'].includes(row?.CustomParameters?.ReversionType)
				); // 2=START; ReversionType=4 (do not revert)
			default:
				return true;
		}
	}
	return false;
};

export const createStrategySaveSimulator = (
	username,
	account,
	recordsInfoPapers,
	rowSelected
) => {
	let strategyToSave = {
		...strategyModel,
		name: `${rowSelected.Name}_${format(new Date(), 'HH:mm:ss')}`,
		saved: false,
		id: new Date().getTime(),
		index: 0,
		userName: username,
		underType: 'PS',
		account,
		createDate: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
	};

	strategyToSave.positions = recordsInfoPapers.map((item, index) => {
		let position = {};

		const indexLegRow = rowSelected.Legs.findIndex(
			legRow => legRow.LegSymbol === item.symbol
		);

		if (indexLegRow >= 0) {
			const isBuyLegSide = rowSelected.Legs[indexLegRow].LegSide === '1';

			position = {
				...positionModel,
				account,
				symbol: item.symbol,
				id: new Date().getTime() + index,
				securityType: item.securityType,
				entryPx: Number(rowSelected.Legs[indexLegRow].LegAvgPx),
				entryPxLocked: true,
				qtty: isBuyLegSide
					? Number(rowSelected.Legs[indexLegRow].LegExecQty)
					: -Number(rowSelected.Legs[indexLegRow].LegExecQty),
				optionType: item.optionType === 'CALL' ? 'C' : 'P',
				strikePx: parseFloat(item.strikePrice),
				daysToExpiration: Number(item.workDaysUntilMaturity),
				index: index,
				margin: parseFloat(item.margin),
				securityId: item.securityId,
				asset: item.asset,
				underlyingSymbol: item.underlyingSymbol ?? '',
			};

			if (item.securityType === 'OPT') {
				position.expirationDate = item.maturityDate;
			}

			if (item.securityType === 'PS') {
				strategyToSave.underSymbol = item.symbol;
			}

			if (['CS', 'PS'].includes(item.securityType)) {
				delete position.optionType;
				delete position.strikePx;
				delete position.daysToExpiration;
				delete position.expirationDate;
			}
		}

		return position;
	});

	return strategyToSave;
};

export const availablePositionsSaveSimulator = strategyToSave => {
	// O array de posições nao pode ter nenhum vazio
	// Todas as pernas precisam ter o mesmo asset
	// Todas as pernas que sao OPT, precisam pertencer ao mesmo underSymbol
	// Todas as pernas que não são OPT precisam ter o mesmo securityType

	const availablesSecutityTypes = [
		...getOptUnderlyingStockSecurityTypes(),
		'OPT',
	];

	const positionIsEmpty = strategyToSave.positions.some(
		position => Object.keys(position).length === 0
	);

	const securityTypes = strategyToSave.positions.map(
		position => position.securityType
	);

	const positionsOpts = strategyToSave.positions.filter(
		position => position.securityType === 'OPT'
	);

	const positionsWithoutOpt = strategyToSave.positions.filter(
		position => position.securityType !== 'OPT'
	);

	if (positionIsEmpty) {
		toast.error('Papel expirado(s) ou não existente(s)!');

		return false;
	} else if (
		!securityTypes.some(securityType =>
			availablesSecutityTypes.includes(securityType)
		)
	) {
		toast.error('Ativo(s) invalidos!');

		return false;
	} else if (
		!strategyToSave.positions.every(
			position => position.asset === strategyToSave.positions[0].asset
		)
	) {
		toast.error(
			'Um ou mais instrumentos não pertecem ao mesmo underlying!'
		);

		return false;
	} else if (
		positionsOpts.length > 0 &&
		!positionsOpts.every(
			position =>
				position.underlyingSymbol === positionsOpts[0].underlyingSymbol
		)
	) {
		toast.error('Um ou mais instrumentos não pertecem ao mesmo underlying');

		return false;
	} else if (
		positionsWithoutOpt.length > 0 &&
		!positionsWithoutOpt.every(
			position =>
				position.securityType === positionsWithoutOpt[0].securityType
		)
	) {
		toast.error('Um ou mais instrumentos não pertecem ao mesmo underlying');

		return false;
	} else {
		return true;
	}
};
