export const idxLiveChartsActionTypes = {
	SET_STRATEGIES: 'idxLiveCharts/SET_STRATEGIES',
	UPDATE_GRADIENT_STOP: 'idxLiveCharts/UPDATE_GRADIENT_STOP',
	SET_VISIBLE_RANGE: 'idxLiveCharts/SET_VISIBLE_RANGE',
	SET_PRICE_RANGE: 'idxLiveCharts/SET_PRICE_RANGE',
	SET_CHART_DATA: 'idxLiveCharts/SET_CHART_DATA',
	UPDATE_CHART_DATA: 'idxLiveCharts/UPDATE_CHART_DATA',
	SET_SCROLL_POSITION: 'idxLiveCharts/SET_SCROLL_POSITION',
	SET_TOGGLE_ASSET: 'idxLiveCharts/SET_TOGGLE_ASSET',
	SET_TOGGLE_COUNT: 'idxLiveCharts/SET_TOGGLE_COUNT',
	RESET_IDX_CHART_STATE: 'idxLiveCharts/RESET_IDX_CHART_STATE',
};
