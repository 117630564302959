import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import { apiMarketData } from 'services/api';
import { getSubExchangeFromSecurityType } from 'utils/strategies';
import { getStrategyTemplate } from 'utils/templates';
import { addClipProportion } from 'utils/addClipProportion';
import { EXM } from 'utils/bulletContext';
import { toast } from 'react-toastify';
import { Box, Typography, Button, colors } from '@mui/material';
import SettingsSuggestTwoToneIcon from '@mui/icons-material/SettingsSuggestTwoTone';
import { setIsGlobalSettingsOpen } from 'store/modules/panelConfig/actions';
import { LightTooltip } from 'components/LightTooltip';

import { RoundButton } from './styles';

import { IdxStops } from './idxStops';

import {
	additionalUpdate,
	orderEntry,
	updateStrategyInfosFromApi,
} from 'store/modules/bottomBullet/actions';

export const IdxBar = ({
	symbolDef,
	finValue,
	isToggledWin,
	isToggledWdo,
	handleClickToggle,
	cancelAllStrategies,
}) => {
	const dispatch = useDispatch();

	const permittedStrategyCodes = useSelector(
		state => state.products.permittedBullets.permittedStrategyCodes
	);

	const buildStrategy = async (strategyCode, isMarketPrice) => {
		if (!permittedStrategyCodes.includes(strategyCode)) {
			toast.error(
				'Esta estratégia não é permitida para suas assinaturas atuais.'
			);
			return;
		}

		const legSide = '1';
		const newLegs = [];
		const legsResponses = [];

		legsResponses.push(
			apiMarketData.get(`/exchanges/BVMF/instruments/${symbolDef}`)
		);
		const strategy = getStrategyTemplate(strategyCode);

		Promise.all(legsResponses).then(values => {
			let strategyUpdated = {};

			let legIndex = 0;

			newLegs.push({
				ILegAllocAccount: '',
				LegMaxClipSize: values[legIndex].data.minOrderQty,
				LegQuantity: values[legIndex].data.minOrderQty,
				LegResting: 'N',
				LegSecurityExchange: getSubExchangeFromSecurityType(
					values[legIndex].data.securityType
				),
				LegSide: legSide,
				LegSymbol: symbolDef,
			});

			strategyUpdated = {
				...strategy,
				StrategyInfos: newLegs.map(() => {
					return {};
				}),
				StrategyCode: strategyCode,
				StrategyLegs: newLegs,
				context: EXM,
			};

			const strategyInfos = values.map(el => el.data);

			addClipProportion(strategyUpdated.StrategyLegs, strategyInfos);

			dispatch(orderEntry(strategyUpdated, 3));
			dispatch(updateStrategyInfosFromApi(strategyUpdated.StrategyLegs));

			dispatch(additionalUpdate('isOpen', true));
			dispatch(additionalUpdate('isRecentListVisible', false));
			dispatch(additionalUpdate('isSavedListVisible', false));
			dispatch(additionalUpdate('isMarketPrice', isMarketPrice));
			dispatch(additionalUpdate('refreshNeeded', true));
		});
	};

	return (
		<Box
			p={2}
			sx={{
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
				borderBottom: '1px solid gray',
			}}
			gap={1}
			width="100%"
		>
			<Box sx={{ display: 'flex', gap: 1 }}>
				<Typography variant="h5">
					<strong>Super GL</strong>
				</Typography>

				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						marginTop: '2px',
						color: 'white',
						padding: '0.25rem 0.5rem',
						borderRadius: '5px',
						fontSize: '0.8rem',
						height: '18px',
						background: colors.red[700],
					}}
				>
					BETA
				</div>

				<LightTooltip arrow title="Habilita/Desabilita grafico de WIN">
					<RoundButton
						variant="contained"
						toggled={isToggledWin}
						onClick={() => handleClickToggle(isToggledWin, 'WIN')}
					>
						WIN
					</RoundButton>
				</LightTooltip>

				<LightTooltip arrow title="Habilita/Desabilita grafico de WDO">
					<RoundButton
						variant="contained"
						toggled={isToggledWdo}
						onClick={() => handleClickToggle(isToggledWdo, 'WDO')}
					>
						WDO
					</RoundButton>
				</LightTooltip>

				<LightTooltip arrow title="Abrir configuração de Stop Global">
					<SettingsSuggestTwoToneIcon
						fontSize="large"
						color="primary"
						onClick={() =>
							dispatch(setIsGlobalSettingsOpen(true, 'gradient'))
						}
						style={{ height: '30px', cursor: 'pointer' }}
					/>
				</LightTooltip>
			</Box>

			<Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
				<IdxStops finValue={finValue} />
				<LightTooltip
					arrow
					title="Cancelar todas as estratégias em visualização"
				>
					<Button
						variant="contained"
						color="error"
						style={{ height: '30px' }}
						onClick={() => cancelAllStrategies('ALL')}
					>
						Cancelar Todas
					</Button>
				</LightTooltip>

				<LightTooltip
					arrow
					title="Cancelar estratégias de WIN em visualização"
				>
					<Button
						variant="contained"
						color="error"
						style={{ height: '30px' }}
						onClick={() => cancelAllStrategies('WIN')}
					>
						Cancelar WIN&apos;s
					</Button>
				</LightTooltip>

				<LightTooltip
					arrow
					title="Cancelar estratégias de WDO em visualização"
				>
					<Button
						variant="contained"
						color="error"
						style={{ height: '30px' }}
						onClick={() => cancelAllStrategies('WDO')}
					>
						Cancelar WDO&apos;s
					</Button>
				</LightTooltip>
			</Box>

			<Box sx={{ display: 'flex', gap: 1 }}>
				<LightTooltip
					arrow
					title="Abrir boleta de gradiente esgotamento"
				>
					<Button
						variant="contained"
						color="success"
						style={{ height: '30px', width: '25px' }}
						onClick={() => buildStrategy('grddin', false)}
					>
						GRD-E
					</Button>
				</LightTooltip>
				<LightTooltip arrow title="Abrir boleta de gradiente a mercado">
					<Button
						variant="contained"
						color="success"
						style={{
							height: '30px',
							width: '50px',
							maxWidth: '50px',
						}}
						onClick={() => buildStrategy('grddin', true)}
					>
						GRD-M
					</Button>
				</LightTooltip>
			</Box>
		</Box>
	);
};
