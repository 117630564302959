import { isValid, parse } from 'date-fns';

export function validateGapTimeFields(customParameters) {
	if (customParameters.PxInitTimeMonitoring) {
		if (customParameters.PxEndTimeMonitoring) {
			customParameters._relativePxInitTimeOption = 'interval';

			if (!isValid(customParameters.PxEndTimeMonitoring)) {
				const parsedEndTime = parse(
					customParameters.PxEndTimeMonitoring,
					'HH:mm:ss',
					new Date()
				);

				customParameters.PxEndTimeMonitoring = parsedEndTime;
			}
		} else {
			customParameters._relativePxInitTimeOption = 'specified';
		}

		if (!isValid(customParameters.PxInitTimeMonitoring)) {
			const parsedInitTime = parse(
				customParameters.PxInitTimeMonitoring,
				'HH:mm:ss',
				new Date()
			);

			customParameters.PxInitTimeMonitoring = parsedInitTime;
		}
	} else {
		customParameters._relativePxInitTimeOption = 'immediate';
	}
}
