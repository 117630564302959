import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, IconButton, Tooltip } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { Book, SwapVert } from '@material-ui/icons';
import Box from '@material-ui/core/Box';
import ComboBoxInput from '../ComboBoxInput';
import MaterialCheckBox from '../MaterialCheckBox';
import { ButtonBuySell } from '../MaterialStyles/style';
import DecimalInput from '../DecimalInput';
import {
	updateStrategyLeg,
	updateCustomParameters,
	additionalUpdate,
} from 'store/modules/bottomBullet/actions';
import paperValidator from 'utils/paperValidation';
import { useTranslation } from 'react-i18next';
import addWidgetFromButton from 'utils/addWidgetFromButton';
import { MarketPrice } from '../../MarketPrice';
import { symbolOpenBook } from 'utils/symbolOpenBook';

const Leg = props => {
	const {
		onSuggestionsFetchRequested,
		setPaperInfo,
		handleChange,
		subUid,
		onlyCall,
		onlyOptions,
		stepFunction,
	} = props;

	const { bullet } = useSelector(state => state.bottomBullet);
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const lockBook = useSelector(state => state.configs.widgetIsLoading);

	const paperFieldRef = useRef(null);

	const bulletLeg = bullet.content.StrategyLegs[0];

	const changeLegSide = () => {
		dispatch(
			updateStrategyLeg(
				{
					LegSide:
						bullet.content.StrategyLegs[0].LegSide === '1'
							? '2'
							: '1',
				},
				0
			)
		);
	};

	const handleOpenPrice = () => {
		handleChange(
			'Custom',
			'UseAuctionOpenPrice',
			bullet.content.CustomParameters.UseAuctionOpenPrice === 'Y'
				? 'N'
				: 'Y',
			0
		);
	};

	function setPriceLimit(type) {
		dispatch(
			updateCustomParameters({
				PriceLimit:
					type === 'ask'
						? bullet.StrategyInfos[0].askPx
						: bullet.StrategyInfos[0].bidPx,
			})
		);
	}

	useEffect(() => {
		const legSymbol = bulletLeg.LegRefSymbol ?? bulletLeg.LegSymbol;

		if (legSymbol?.startsWith('WDO') || legSymbol?.startsWith('WIN')) {
			paperFieldRef.current?.focus();
			paperFieldRef.current?.blur();
			dispatch(additionalUpdate('refreshNeeded', false));
		}
	}, [bullet.refreshNeeded]);

	return (
		<Grid item xs={subUid === 9 ? 12 : subUid > 5 ? 9 : 6}>
			<Grid container spacing={1} direction="row">
				<Grid item xs={12} style={{ marginLeft: '2px' }}>
					<Typography variant="subtitle1">
						{subUid !== 4 ? 'Entrada' : 'Posição'}
					</Typography>
				</Grid>
				<Box pb={0.5}>
					<Tooltip title="Abrir book">
						<IconButton
							size="small"
							onClick={() =>
								addWidgetFromButton(
									bullet.content.StrategyLegs[0].LegSymbol
								)
							}
							disabled={
								symbolOpenBook(
									bullet.StrategyInfos[0],
									bullet
								) || lockBook
							}
						>
							<Book />
						</IconButton>
					</Tooltip>
				</Box>
				<Grid item xs>
					<ComboBoxInput
						name="paperField"
						label="Ativo"
						setPaperInfo={setPaperInfo}
						inputRef={paperFieldRef}
						comboOptions={
							bullet.suggestions && bullet.suggestions.source
								? {
									value: bullet.suggestions.source.id,
									title: bullet.suggestions.source.symbol,
								  }
								: bullet.suggestions
						}
						onChange={e =>
							onSuggestionsFetchRequested(
								e.target.value,
								onlyOptions,
								onlyCall,
								0
							)
						}
						value={
							bullet.content.StrategyLegs[0].LegSymbol
								? bullet.content.StrategyLegs[0].LegSymbol.toUpperCase()
								: ''
						}
						disabled={bullet.content.ClOrdID}
						isPaper
					/>
				</Grid>
				<Grid item xs>
					<ButtonBuySell
						name="singleSideButtom"
						variant="contained"
						fullWidth
						size="small"
						color={
							subUid === 4
								? bullet.content.StrategyLegs[0].LegSide === '2'
									? 'primary'
									: 'secondary'
								: bullet.content.StrategyLegs[0].LegSide === '1'
									? 'primary'
									: 'secondary'
						}
						startIcon={<SwapVert />}
						onClick={changeLegSide}
						disabled={paperValidator(
							bullet.StrategyInfos[0],
							bullet,
							'singleSideButtom'
						)}
					>
						{subUid === 4
							? bullet.content.StrategyLegs[0].LegSide === '2'
								? 'Comprei'
								: 'Vendi'
							: bullet.content.StrategyLegs[0].LegSide === '1'
								? 'Compra'
								: 'Venda'}
					</ButtonBuySell>
				</Grid>
				<Grid item xs>
					<DecimalInput
						name="qtyField"
						disabled={
							paperValidator(
								bullet.StrategyInfos[0],
								bullet,
								'marketQty'
							) ||
							(bullet.content.CustomParameters.CustomOrdType ===
								3 &&
								bullet.content.ClOrdID)
						}
						label="Quantidade"
						minVal={bullet.StrategyInfos[0].minOrderQty ?? 0}
						maxVal={bullet.StrategyInfos[0].maxOrderQty ?? 9999999}
						minDecScale={0} // casas decimais
						stepFunction={stepFunction}
						stepType={
							bullet.content.ClOrdID &&
							bullet.content.StrategyCode === 'fxorder'
								? 'Custom'
								: bullet.content.CustomParameters.OrderQuantity
									? 'Custom'
									: 'Leg'
						} // type
						stepSubType={
							bullet.content.ClOrdID &&
							bullet.content.StrategyCode === 'fxorder'
								? 'OrderQuantity'
								: bullet.content.CustomParameters.OrderQuantity
									? 'OrderQuantity'
									: 'LegQuantity'
						} // subtype
						stepSize={
							bullet.StrategyInfos[0].roundLot ||
							bullet.StrategyInfos[0].Cst_MinOrderQuantity
						} // incremento
						valueToStep={
							bullet.content.ClOrdID &&
							bullet.content.StrategyCode === 'fxorder'
								? bullet.content.CustomParameters.OrderQuantity
								: bullet.content.CustomParameters.OrderQuantity
									? bullet.content.CustomParameters.OrderQuantity
									: bullet.content.StrategyLegs[0].LegQuantity
						} // valor a ser modificado
						value={
							bullet.content.ClOrdID &&
							bullet.content.StrategyCode === 'fxorder'
								? bullet.content.CustomParameters.OrderQuantity
								: bullet.content.CustomParameters.OrderQuantity
									? bullet.content.CustomParameters.OrderQuantity
									: bullet.content.StrategyLegs[0].LegQuantity
						}
						onChange={e =>
							handleChange(
								bullet.content.ClOrdID &&
									bullet.content.StrategyCode === 'fxorder'
									? 'Custom'
									: bullet.content.CustomParameters
										.OrderQuantity
										? 'Custom'
										: 'Leg',
								bullet.content.ClOrdID &&
									bullet.content.StrategyCode === 'fxorder'
									? 'OrderQuantity'
									: bullet.content.CustomParameters
										.OrderQuantity
										? 'OrderQuantity'
										: 'LegQuantity',
								parseInt(e.value, 10),
								0
							)
						}
					/>
				</Grid>

				{subUid === 7 && (
					<Grid item xs>
						<DecimalInput
							name="clipField"
							label="Clip máximo"
							iconTooltip={t('bullets.general.clip')}
							disabled={paperValidator(
								bullet.StrategyInfos[0],
								bullet
							)}
							minVal={bullet.StrategyInfos[0].minOrderQty ?? 0}
							maxVal={
								bullet.StrategyInfos[0].maxOrderQty ?? 9999999
							}
							minDecScale={0} // casas decimais
							stepFunction={stepFunction}
							stepType="Leg" // type
							stepSubType="LegMaxClipSize" // subtype
							stepSize={
								bullet.StrategyInfos[0].roundLot ||
								bullet.StrategyInfos[0].Cst_MinOrderQuantity
							} // incremento
							valueToStep={
								bullet.content.StrategyLegs[0].LegMaxClipSize
							} // valor a ser modificado
							value={
								bullet.content.StrategyLegs[0].LegMaxClipSize
							}
							onChange={e =>
								handleChange(
									'Leg',
									'LegMaxClipSize',
									parseInt(e.value, 10),
									0
								)
							}
						/>
					</Grid>
				)}

				{subUid === 5 || subUid === 6 ? (
					<Grid item xs>
						<Box>
							<MaterialCheckBox
								label="Preço de Abertura"
								color="default"
								className="bullet-checkbox"
								onChange={handleOpenPrice}
								disabled={bullet.content.ClOrdID}
								checked={
									bullet.content.CustomParameters
										.UseAuctionOpenPrice === 'Y'
								}
								smallLabel
							/>
						</Box>
					</Grid>
				) : (
					''
				)}
				{subUid !== 2 || subUid === 1 ? (
					<Grid item xs>
						<DecimalInput
							name="priceField"
							iconTooltip={
								subUid === 3
									? t('bullets.single.start_price')
									: subUid === 4
										? t('bullets.single.exit_price')
										: t('bullets.general.limit_price')
							}
							disabled={
								paperValidator(
									bullet.StrategyInfos[0],
									bullet
								) ||
								(bullet.content.ClOrdID && subUid === 4)
							}
							label={
								subUid === 3
									? 'Preço Start'
									: subUid === 4
										? 'Preço Entrada'
										: 'Preço Limite'
							}
							minVal={0}
							maxVal={999999.99}
							minDecScale={
								bullet.StrategyInfos[0].tickSizeDenominator
							} // casas decimais
							stepFunction={stepFunction}
							stepType="Custom" // type
							stepSubType="PriceLimit" // subtype
							stepSize={
								bullet.StrategyInfos[0].minPriceIncrement ||
								bullet.StrategyInfos[0].Cst_MinPriceIncrement ||
								bullet.StrategyInfos[1]?.Cst_MinPriceIncrement
							} // incremento
							valueToStep={
								bullet.content.CustomParameters.PriceLimit
							} // valor a ser modificado
							value={
								bullet.content.ClOrdID || bullet.clone
									? bullet.content.CustomParameters
										.PriceLimit ||
									  bullet.content.CustomParameters
									  	.EntranceExecPrice
									: bullet.content.CustomParameters.PriceLimit
							}
							onChange={e =>
								handleChange('Custom', 'PriceLimit', e.value, 0)
							}
						/>
					</Grid>
				) : (
					''
				)}

				{bullet.subUid !== 5 && (
					<Grid item xs style={{ marginTop: '-24px' }}>
						<MarketPrice
							setPriceLimit={setPriceLimit}
							disabled={bullet.content.ClOrdID && subUid === 4}
						/>
					</Grid>
				)}

				{subUid === 9 && (
					<>
						<Grid item xs>
							<DecimalInput
								name="maxClip"
								label="Clip máximo"
								iconTooltip={t('bullets.general.clip')}
								disabled={paperValidator(
									bullet.StrategyInfos[0],
									bullet
								)}
								minVal={
									bullet.StrategyInfos[0].minOrderQty ?? 0
								}
								maxVal={
									bullet.StrategyInfos[0].maxOrderQty ??
									9999999
								}
								minDecScale={0} // casas decimais
								stepFunction={stepFunction}
								stepType="Leg" // type
								stepSubType="LegMaxClipSize" // subtype
								stepSize={
									bullet.StrategyInfos[0].roundLot ||
									bullet.StrategyInfos[0].Cst_MinOrderQuantity
								} // incremento
								valueToStep={
									bullet.content.StrategyLegs[0]
										.LegMaxClipSize
								} // valor a ser modificado
								value={
									bullet.content.StrategyLegs[0]
										.LegMaxClipSize
								}
								onChange={e =>
									handleChange(
										'Leg',
										'LegMaxClipSize',
										parseInt(e.value, 10),
										0
									)
								}
							/>
						</Grid>
						<Grid item xs>
							<DecimalInput
								name="minClip"
								label="Clip mínimo"
								iconTooltip={t('bullets.single.min_clip')}
								disabled={paperValidator(
									bullet.StrategyInfos[0],
									bullet
								)}
								minVal={
									bullet.StrategyInfos[0].minOrderQty ?? 0
								}
								maxVal={
									bullet.StrategyInfos[0].maxOrderQty ??
									9999999
								}
								minDecScale={0} // casas decimais
								stepFunction={stepFunction}
								stepType="Leg" // type
								stepSubType="LegMinClipSize" // subtype
								stepSize={
									bullet.StrategyInfos[0].roundLot ||
									bullet.StrategyInfos[0].Cst_MinOrderQuantity
								} // incremento
								valueToStep={
									bullet.content.StrategyLegs[0]
										.LegMinClipSize
								} // valor a ser modificado
								value={
									bullet.content.StrategyLegs[0]
										.LegMinClipSize
								}
								onChange={e =>
									handleChange(
										'Leg',
										'LegMinClipSize',
										parseInt(e.value, 10),
										0
									)
								}
							/>
						</Grid>
						<Grid item xs>
							<DecimalInput
								name="restingPrice"
								iconTooltip={t('bullets.single.resting_price')}
								disabled={paperValidator(
									bullet.StrategyInfos[0],
									bullet
								)}
								label="Dif. preço apregoar"
								minVal={0}
								maxVal={9999.99}
								minDecScale={2} // casas decimais
								stepFunction={stepFunction}
								stepType="Custom" // type
								stepSubType="RestingPrice" // subtype
								stepSize={
									bullet.StrategyInfos[0].minPriceIncrement ||
									bullet.StrategyInfos[0]
										.Cst_MinPriceIncrement
								} // incremento
								valueToStep={
									bullet.content.CustomParameters.RestingPrice
								} // valor a ser modificado
								value={
									bullet.content.CustomParameters.RestingPrice
								}
								onChange={e =>
									handleChange(
										'Custom',
										'RestingPrice',
										parseFloat(e.value),
										0
									)
								}
							/>
						</Grid>
						<Grid item xs>
							<DecimalInput
								name="restingQuantity"
								iconTooltip={t(
									'bullets.single.resting_quantity'
								)}
								disabled={paperValidator(
									bullet.StrategyInfos[0],
									bullet
								)}
								label="Qtd. apregoar"
								minVal={
									bullet.StrategyInfos[0].minOrderQty ?? 0
								}
								maxVal={
									bullet.StrategyInfos[0].maxOrderQty ??
									9999999
								}
								minDecScale={0} // casas decimais
								stepFunction={stepFunction}
								stepType="Custom" // type
								stepSubType="RestingQuantity" // subtype
								stepSize={
									bullet.StrategyInfos[0].roundLot ||
									bullet.StrategyInfos[0].Cst_MinOrderQuantity
								} // incremento
								valueToStep={
									bullet.content.CustomParameters
										.RestingQuantity
								} // valor a ser modificado
								value={
									bullet.content.CustomParameters
										.RestingQuantity
								}
								onChange={e =>
									handleChange(
										'Custom',
										'RestingQuantity',
										parseInt(e.value, 10),
										0
									)
								}
							/>
						</Grid>
					</>
				)}
			</Grid>
		</Grid>
	);
};

export default Leg;
