const haveNull = obj => {
	return Object.keys(obj).find(key => {
		return obj[key] === null || obj[key] === undefined || (isNaN(obj[key]) && typeof obj[key] !== 'string');
	});
};

export const generalValidation = bullet => {
	const validateCustom = haveNull(bullet.CustomParameters);
	if (bullet.StrategyCode !== 'portbalance' && bullet?.StrategyLegs?.length > 0) {
		const validateLegs = [];
		bullet.StrategyLegs.forEach(el => {
			validateLegs.push(haveNull(el));
		});
		return validateCustom || validateLegs.filter(e => e !== undefined);
	}

	return validateCustom;
};

const bulletLabels = [
	{ value: 'LegSymbol', label: 'Papel' },
	{ value: 'LegQuantity', label: 'Quantidade' },
	{ value: 'LegPrice', label: 'Preço' },
	{ value: 'LegMaxClipSize', label: 'Clip Máximo' },
	{ value: 'LegMinClipSize', label: 'Clip Mínimo' },
	{ value: 'OrderQuantity', label: 'Quantidade' },
	{ value: 'StopGain', label: 'Stop Gain' },
	{ value: 'StopLoss', label: 'Stop Loss' },
	{ value: 'StopGainL1', label: 'Stop Gain L1' },
	{ value: 'StopGainL2', label: 'Stop Gain L2' },
	{ value: 'StopGainL3', label: 'Stop Gain L3' },
	{ value: 'OrderQuantityL1', label: 'Quantidade L1' },
	{ value: 'OrderQuantityL2', label: 'Quantidade L2' },
	{ value: 'OrderQuantityL3', label: 'Quantidade L3' },
	{ value: 'RestingPrice', label: 'Dif. Preço Apregoar' },
	{ value: 'RestingQuantity', label: 'Quantidade Apregoar' },
	{ value: 'TriggerValue', label: 'Entrada'},
	{ value: 'ToMarketTriggerValue', label: 'Loss'},
	{ value: 'IgnoreOffersLT', label: 'Ignorar múltiplo de lote'},
	{ value: 'EntrancePrice', lable: 'Preço Início de Range'},
	{ value: 'StartWhenCross', label: 'Antecipar em Ticks'},
	{ value: 'NumberOfEntrances', label: 'Quantidade de Níveis de Entradas'},
	{ value: 'IncrementPriceStepEntrance', label: 'Espaçamento entre as Entradas'},
	{ value: 'IncrementPriceStopGain', label: 'Objetivo de Gain'},
	{ value: 'StopLossFinance', label: 'Stop Loss Financeiro'},
	{ value: 'StopByPrice1', lable: 'Stop Loss Por Preço'},
	{ value: 'StopGainGlobalFinance', label: 'Stop Gain Financeiro'},
	{ value: 'StopByPrice2', label: 'Stop Gain Por Preço'},
	{ value: 'PreservationStartTrigger', label: 'Gatilho Financeiro'},
	{ value: 'PreservationPercentual', label: '% Stop'},
	{ value: 'MaximumZeroTimeWindow', label: 'Esperar para iniciar'},
	{ value: 'RetroalimentParam1', label: 'Valor Ajuste Dinâmico'},
	{ value: 'DeltaFixedValue', label: 'Valor de Delta'},
	{ value: 'AgressorThresholdOn', label: 'Ativação do Agressor Threshold'},
	{ value: 'AgressorThresholdOff', label: 'Desativação do Agressor Threshold'},
	{ value: 'StopLossFinance', label: 'Stop Loss Financeiro'},
	{ value: 'StopPositionQuantity', label: 'Qtd Máxima em Aberto'},
	{ value: 'StopLossPerc', label: 'Limite de Perda na Apregoação'},
	{ value: 'StopGainPerc', label: 'Limite de Ganho na Apregoação'},
	{ value: 'RevertOnExecQty', label: 'Qtd em Aberto para Iniciar Reversão'},
];

export const returnCorrectLabel = value => {
	console.log(value, typeof value);
	
	if (typeof value === 'string') {
		return bulletLabels.find(el => el.value === value)?.label;
	} else {
		return bulletLabels.find(el => el.value === value[0])?.label;
	}
};