import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, MenuList, MenuItem, CircularProgress } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { store } from 'store';

import { BulletSettings } from './BulletSettings';
import { GeneralSettings } from './GeneralSettings';
import { SimulatorSettings } from './SimulatorSettings';
import { AuthenticationSettings } from './AuthenticationSettings';
import { ModalTemplate } from 'components/AppHeader/Modals/Template';

import { postGlobalSettings } from 'services/userPreferences/globalSettings';
import {
	resetChanges,
	setGlobalSettingsModule,
} from 'store/modules/panelConfig/actions';
import { GradientSettings } from './GradientSettings';
import { APPLICATION_MODULES } from 'utils/constants';

export const GlobalSettings = ({ open, onClose }) => {
	const dispatch = useDispatch();

	const activeModule = useSelector(
		state => state.configs?.globalSettings.module
	);
	const { accountType } = useSelector(state => state.auth);
	const activeProduct = useSelector(state => state.products.activeProduct);

	const [isLoading, setIsLoading] = useState(false);

	function handleSubmit() {
		setIsLoading(true);

		const username = store.getState().auth.username;
		const globalSettings =
			store.getState().configs.globalSettings.settingsToChange;

		postGlobalSettings(username, globalSettings, setIsLoading, handleClose);
	}

	const modulesComponents = {
		general: <GeneralSettings />,
		bullet: <BulletSettings />,
		simulator: <SimulatorSettings />,
		authentication: <AuthenticationSettings />,
		gradient: <GradientSettings />,
	};

	const modules = [
		{
			value: 'general',
			label: 'Geral',
			permission: true,
		},
		{
			value: 'bullet',
			label: 'Boletas',
			permission: true,
		},
		{
			value: 'simulator',
			label: 'Simulador',
			permission: true,
		},
		{
			value: 'authentication',
			label: 'Autenticação',
			permission: accountType === 'real',
		},
		{
			value: 'gradient',
			label: 'Gradiente',
			permission: activeProduct?.attributes?.modules.includes(
				APPLICATION_MODULES.SUPER_GL
			),
		},
	];

	const permittedModels = modules.filter(module => module.permission);

	function handleClose() {
		dispatch(
			resetChanges(store.getState().configs.globalSettings.userSettings)
		);
		onClose();
	}

	return (
		<ModalTemplate
			open={open}
			onClose={handleClose}
			title="Configurações do sistema"
			closeButton={handleClose}
		>
			<Box
				sx={{
					width: '50rem',
					display: 'flex',
					height: '40rem',
				}}
			>
				<MenuList sx={{ borderRight: '1px solid #424242' }}>
					{permittedModels.map((module, index) => (
						<MenuItem
							key={module.value}
							onClick={() =>
								dispatch(setGlobalSettingsModule(module.value))
							}
							sx={{
								p: 1.5,
								borderBottom:
									index !== permittedModels - 1
										? '1px solid #424242'
										: '',
								background:
									activeModule === module.value
										? '#424242'
										: '',
								borderRadius:
									index === 0
										? '5px 0 0 0'
										: index === permittedModels - 1
											? '0 0 0 5px'
											: '',
								'&:hover': {
									background:
										activeModule === module.value
											? '#424242'
											: '#4242427b',
								},
							}}
						>
							{module.label}
						</MenuItem>
					))}
				</MenuList>

				<Box
					sx={{
						flex: 1,
						p: 1,
						display: 'grid',
						gridTemplateRows: '1fr 40px',
					}}
				>
					<Box
						sx={{
							p: 1,
							overflowY: 'scroll',
							scrollbarWidth: 'none',
						}}
					>
						{modulesComponents[activeModule]}
					</Box>

					{!['authentication', 'gradient'].includes(activeModule) && (
						<Box
							sx={{
								width: '100%',
								display: 'flex',
								justifyContent: 'flex-end',
								mt: 1,
							}}
						>
							<LoadingButton
								variant="contained"
								loading={isLoading}
								loadingIndicator={
									<CircularProgress
										color="primary"
										size={16}
									/>
								}
								onClick={handleSubmit}
								sx={{
									'&.Mui-disabled': {
										backgroundColor: '#ffffff1f !important',
									},
								}}
							>
								Salvar
							</LoadingButton>
						</Box>
					)}
				</Box>
			</Box>
		</ModalTemplate>
	);
};
