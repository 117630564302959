import React from 'react';
import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
	Box,
	Button,
	ButtonGroup,
	colors,
	Grid,
	TextField,
	Typography,
} from '@mui/material';
import { Info } from '@mui/icons-material';

import { NumericInput } from 'components/Bullet/NumericInput';
import { DescriptionTooltip, LightHTMLTooltip } from 'components/HelperTooltip';

import paperValidator from 'utils/paperValidation';
import { updateCustomParameters } from 'store/modules/bottomBullet/actions';

export const GapConfigParams = () => {
	const dispatch = useDispatch();

	const bullet = useSelector(state => state.bottomBullet.bullet);

	const { content, StrategyInfos } = bullet;

	const {
		TargetPrice,
		RelativeDirection,
		RelativeType,
		RelativeUnit,
		RelativePxRel,
		FixedPrice,
	} = content.CustomParameters;

	const { minPriceIncrement, tickSizeDenominator } = StrategyInfos[0];

	const targetPriceOptions = [
		{ value: 'F', label: 'Fixo' },
		{ value: 'R', label: 'Relativo' },
	];

	const relativeDirectionOptions = [
		{ value: 'U', label: 'Acima' },
		{ value: 'D', label: 'Abaixo' },
	];

	const relativeTypeOptions = [
		{ value: 'O', label: 'Preço abertura dia atual' },
		{ value: 'C', label: 'Preço fechamento dia anterior ' },
		{ value: 'L', label: 'Mínima dia atual' },
		{ value: 'H', label: 'Máxima dia atual' },
	];

	const notSelectedButtonStyle = {
		color: colors.grey[500],
		borderColor: colors.grey[600],
	};

	const isBulletDisabled = paperValidator(StrategyInfos[0], bullet);
	const isBulletDisabledBySingleSideButton = paperValidator(
		StrategyInfos[0],
		bullet,
		'singleSideButtom'
	);

	function handleUpdateCustomParameters(field, value) {
		dispatch(
			updateCustomParameters({
				[field]: value,
			})
		);
	}

	function handleToggleRelativeUnit() {
		dispatch(
			updateCustomParameters({
				RelativeUnit: RelativeUnit === 'I' ? 'P' : 'I',
				RelativePxRel: '',
			})
		);
	}

	return (
		<Grid
			item
			container
			sx={{
				p: 1,
				gap: 1,
				flex: 1,
				borderRadius: 1,
				flexDirection: 'column',
				justifyContent: 'space-evenly',
				bgcolor: colors.grey[900],
			}}
		>
			<Box
				sx={{
					display: 'flex',
					gap: 1,
				}}
			>
				<Typography variant="subtitle1">
					Configuração de entrada
				</Typography>

				<LightHTMLTooltip
					element={<Info fontSize="small" sx={{ mt: 0.25 }} />}
				>
					<DescriptionTooltip
						label="Início de range"
						description={t('bullets.gradient.entrance_price')}
					/>
				</LightHTMLTooltip>
			</Box>

			<Grid item container spacing={1}>
				<Grid item xs={TargetPrice === 'F' ? 3 : true}>
					<label htmlFor="TargetPrice">Preço alvo</label>
					<TextField
						id="TargetPrice"
						name="TargetPrice"
						labelId="TargetPrice-label"
						value={TargetPrice}
						SelectProps={{ native: 'native' }}
						fullWidth
						size="small"
						disabled={isBulletDisabled}
						defaultValue="F"
						InputProps={{ style: { height: 25 } }}
						InputLabelProps={{ shrink: true }}
						select
						onChange={event =>
							handleUpdateCustomParameters(
								event.target.name,
								event.target.value
							)
						}
					>
						{targetPriceOptions.map(account => (
							<option key={account.value} value={account.value}>
								{account.label}
							</option>
						))}
					</TextField>
				</Grid>

				{TargetPrice === 'F' ? (
					<Grid item xs={3}>
						<NumericInput
							label="Preço (pontos)"
							value={FixedPrice ?? ''}
							disabled={isBulletDisabled}
							roundOnBlur
							stepSize={minPriceIncrement}
							minValue={minPriceIncrement}
							onChangeCallback={value =>
								handleUpdateCustomParameters(
									'FixedPrice',
									value
								)
							}
							decimalScale={tickSizeDenominator}
							fixedDecimalScale
						/>
					</Grid>
				) : (
					<>
						<Grid item xs>
							<label htmlFor="RelativeDirection">Direção</label>
							<TextField
								id="RelativeDirection"
								name="RelativeDirection"
								labelId="RelativeDirection-label"
								value={RelativeDirection}
								SelectProps={{ native: 'native' }}
								disabled={isBulletDisabled}
								fullWidth
								size="small"
								defaultValue="U"
								InputProps={{ style: { height: 25 } }}
								InputLabelProps={{ shrink: true }}
								select
								onChange={event =>
									handleUpdateCustomParameters(
										event.target.name,
										event.target.value
									)
								}
							>
								{relativeDirectionOptions.map(account => (
									<option
										key={account.value}
										value={account.value}
									>
										{account.label}
									</option>
								))}
							</TextField>
						</Grid>

						<Grid item xs>
							<label htmlFor="RelativeType">Tipo</label>
							<TextField
								id="RelativeType"
								name="RelativeType"
								labelId="RelativeType-label"
								value={RelativeType}
								disabled={isBulletDisabled}
								SelectProps={{ native: 'native' }}
								fullWidth
								size="small"
								defaultValue="O"
								InputLabelProps={{ shrink: true }}
								select
								InputProps={{ style: { height: 25 } }}
								onChange={event =>
									handleUpdateCustomParameters(
										event.target.name,
										event.target.value
									)
								}
							>
								{relativeTypeOptions.map(account => (
									<option
										key={account.value}
										value={account.value}
									>
										{account.label}
									</option>
								))}
							</TextField>
						</Grid>

						<Grid
							item
							xs
							sx={{
								display: 'flex',
								alignItems: 'flex-end',
							}}
						>
							<ButtonGroup
								fullWidth
								size="small"
								sx={{
									mb: 0.25,
									height: 25,
									cursor: isBulletDisabledBySingleSideButton
										? 'not-allowed'
										: 'auto',
								}}
								onClick={handleToggleRelativeUnit}
								disabled={isBulletDisabledBySingleSideButton}
							>
								<Button
									color={
										RelativeUnit === 'I'
											? 'primary'
											: 'inherit'
									}
									variant={
										RelativeUnit === 'I'
											? 'contained'
											: 'outlined'
									}
									sx={
										RelativeUnit === 'P'
											? notSelectedButtonStyle
											: {}
									}
								>
									Pontos
								</Button>

								<Button
									color={
										RelativeUnit === 'P'
											? 'primary'
											: 'inherit'
									}
									variant={
										RelativeUnit === 'P'
											? 'contained'
											: 'outlined'
									}
									sx={
										RelativeUnit === 'I'
											? notSelectedButtonStyle
											: {}
									}
								>
									%
								</Button>
							</ButtonGroup>
						</Grid>

						<Grid item xs>
							<NumericInput
								label={`Valor relativo (${
									RelativeUnit === 'P' ? '%' : 'Pontos'
								})`}
								suffix={RelativeUnit === 'P' ? '%' : ''}
								onChangeCallback={value =>
									handleUpdateCustomParameters(
										'RelativePxRel',
										value
									)
								}
								value={RelativePxRel ?? ''}
								stepSize={
									RelativeUnit === 'P'
										? 0.01
										: minPriceIncrement
								}
								minValue={
									RelativeUnit === 'P'
										? 0.01
										: minPriceIncrement
								}
								disabled={isBulletDisabled}
								roundOnBlur
								decimalScale={
									RelativeUnit === 'P'
										? 2
										: tickSizeDenominator
								}
								fixedDecimalScale
							/>
						</Grid>
					</>
				)}
			</Grid>
		</Grid>
	);
};
