import { store } from 'store';
import { apiAWS } from 'services/api';
import { setGotNotifications, setModals } from 'store/modules/userPreferences/actions';

export const getNotifications = (username) => {
	apiAWS
		.get(`/userpreferences/${username}?service=notifications`)
		.then((response) => {
			const modals = response.data;
			delete modals.service_name;
			delete modals.user_name;
			if ([undefined, null].includes(modals?.newGradientModule)) {
				modals.newGradientModule = true;
			}

			store.dispatch(setModals(modals));
		})
		.catch((error) => {
			store.dispatch(setModals({ newGradientModule: true }));
			console.log(error);
		})
		.finally(() => {
			store.dispatch(setGotNotifications(true));
		});
};

export const postNotification = (username, token) => {
	const body = store.getState().userPreferences.modals;

	const headers = { headers: { Authorization: `Bearer ${token}` } };

	apiAWS
		.post(`/userpreferences/${username}?service=notifications`, body, headers)
		.catch((error) => console.error(error));
};
